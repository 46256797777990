import React from "react";

import { Button, Col, Row } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import LoadingSpin from "react-loading-spin";
import { getTodayEpidemiologicalWeek } from "../../../../services/utils/todayEpidemiologicalWeek";
import {
  fetchTrapTypesData,
  getPeriodsToSendOnRequest,
  getTerritorializationsIdsToSendOnRequest,
  getUsersIdsToSendOnRequest,
  selectComponentStyles,
  trapsColorStatusesOptionsList,
  trapsStatusesOptionsList,
} from "../../../../services/utils/globalFunctions";
import moment from "moment";
import useDemandsList from "../../../../hooks/useDemandsList";
import useTrapTypesList from "../../../../hooks/useTrapTypesList";
import useTerritorializationsList from "../../../../hooks/useTerritorializationsList";
import useTeamsList from "../../../../hooks/useTeamsList";
import useTypeLayersList from "../../../../hooks/useTypeLayersList";
import useEmployeesList from "../../../../hooks/useEmployeesList";
import { toast } from "react-toastify";
import useUserPreferences from "../../../../hooks/useUserPreferences";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import useTrapsList from "../../../../hooks/useTrapsList";
import { getTrapRange } from "../../../../constants/RangeTrapConstant";
import { getUserData } from "../../../../services/utils/auth";
import { fetchFilteredTraps } from "../../../../services/api/Trap";

const DEFAULT_FILTERS = {
  beginDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
  colorStatuses: trapsColorStatusesOptionsList,
  demand: {
    label: "Todas",
    value: "",
  },
  employees: [],
  endDate: moment().format("YYYY-MM-DD"),
  statuses: trapsStatusesOptionsList,
  team: {
    label: "Todos",
    members: [],
    value: "",
  },
  territorializations: [],
  trapType: {
    label: "Selecione",
    trapTypeName: "",
    value: "",
  },
  typeLayers: [],
};

const useTrapsPageFilters = () => {
  const [filters, setFilters] = React.useState(DEFAULT_FILTERS);
  const [isTrapsListLoading, setIsTrapsListLoading] = React.useState(false);
  const { demandsList } = useDemandsList();
  const { employeesList } = useEmployeesList();
  const { setTrapsList } = useTrapsList();
  const { teamsList } = useTeamsList();
  const { territorializationsList } = useTerritorializationsList();
  const { trapTypesList } = useTrapTypesList();
  const { typeLayersList } = useTypeLayersList();
  const { preferences } = useUserPreferences();
  const {
    setAppliedFilters
  } = useAppliedFilters();

  React.useEffect(() => {
    if (
      !demandsList ||
      demandsList.length === 0 ||
      !employeesList ||
      employeesList.length === 0 ||
      !preferences ||
      !teamsList ||
      !teamsList.length === 0 ||
      !territorializationsList ||
      territorializationsList.length === 0 ||
      !trapTypesList ||
      trapTypesList.length === 0
    )
      return;

    applyUserFiltersPreference();
  }, [
    demandsList,
    employeesList,
    preferences,
    teamsList,
    territorializationsList,
    trapTypesList,
  ]);

  const applyUserFiltersPreference = async () => {
    const organizationId = getUserData("organizationId");

    const adiTrapOption = trapTypesList.find(
      ({ trapTypeName }) => trapTypeName === "armadilhaDisseminadoraInseticida"
    );

    try {
      if (!("trapsPage" in preferences)) {
        if (organizationId === "8e34767e-3673-4606-9e47-dd67edd2677d") {
          const ovitrapOption = trapTypesList.find(
            ({ trapTypeName }) => trapTypeName === "armadilhaOvos"
          );

          setFilters((previousValues) => {
            const newValues = { ...previousValues };
            newValues.trapType = ovitrapOption;

            fetchAllComponentsData(newValues);

            return newValues;
          });

          return;
        } else {
          setFilters((previousValues) => {
            const newValues = { ...previousValues };
            newValues.trapType = adiTrapOption;

            fetchAllComponentsData(newValues);

            return newValues;
          });
        }

        return;
      }

      setFilters((previousValues) => {
        const newValues = { ...previousValues };

        newValues.demand = preferences.trapsPage.demand;
        newValues.trapType = preferences.trapsPage.trapType;
        newValues.employees = preferences.trapsPage.employee;
        newValues.team = preferences.trapsPage.team;
        newValues.territorializations =
          preferences.trapsPage.territorialization;

        if (newValues.trapType.value === "") newValues.trapType = adiTrapOption;

        fetchAllComponentsData(newValues);

        return newValues;
      });
    } catch (error) {
      if (error instanceof Error) {
        toast.error(
          "Ocorreu um erro ao definir as preferências. Aplique manualmente os filtres e clique no botão 'Filtrar'."
        );
        console.error(error.message);
      }
    }
  };

  const fetchAllComponentsData = React.useCallback(
    async (activeTrapsFilters) => {
      setTrapsList([]);

      setAppliedFilters(activeTrapsFilters);

      await Promise.all([fetchTrapsListData(activeTrapsFilters)]);
    },
    []
  );

  const fetchTrapsListData = async (filters) => {
    if (filters.colorStatuses.length === 0) {
      toast.error("É necessário selecionar pelo menos uma situação por cor.");

      return;
    }

    if (filters.statuses.length === 0) {
      toast.error("É necessário selecionar pelo menos um status.");

      return;
    }

    if (filters.trapType.value === "") {
      toast.error("É necessário selecionar um tipo de armadilha.");

      return;
    }

    setIsTrapsListLoading(true);

    const trapTypes = await fetchTrapTypesData();

    const trapsRangesToSend = trapTypes.map(({ trapTypeName, value }) => {
      const { quantityRange, data } = getTrapRange(trapTypeName);

      return {
        trapTypeId: value,
        quantity: quantityRange,
        begin: data.beggining,
        middle: !data.middle ? null : data.middle,
        end: data.end,
      };
    });

    const organizationId = getUserData("organizationId");
    const usersIdsToSend = getUsersIdsToSendOnRequest(filters);
    const periodsToSend = getPeriodsToSendOnRequest(filters);
    const territorializationsIdsToSend =
      getTerritorializationsIdsToSendOnRequest(filters);

    const filtersToSend = {
      colorStatuses: filters.colorStatuses.map(({ value }) => value),
      demandsIds: [],
      finalDate: periodsToSend.finalDate,
      organizationId,
      ranges: trapsRangesToSend,
      statuses: filters.statuses.map(({ value }) => value),
      territorializationsIds: territorializationsIdsToSend,
      trapsNumbers: [],
      trapTypesIds: [],
      usersIds: usersIdsToSend,
    };

    try {
      const { data, status } = await fetchFilteredTraps(filtersToSend);

      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelas armadilhas. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      setTrapsList(data);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error.message);
      }
    } finally {
      setIsTrapsListLoading(false);
    }
  };

  const handleFiltersChange = (filterName, newValue) => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };

      newValues[filterName] = newValue;

      return newValues;
    });
  };
  
  const handleTypeLayerChange = (value) => {
    handleFiltersChange(
      "typeLayers",
      !value || value.length === 0 ? [] : value
    );

    if (!value || value.length === 0)
      handleFiltersChange("territorializations", []);
    else
      handleFiltersChange("territorializations", value[0].territorializations);
  };

  return {
    demandsList,
    employeesList,
    fetchAllComponentsData,
    filters,
    handleFiltersChange,
    handleTypeLayerChange,
    isTrapsListLoading,
    teamsList,
    territorializationsList,
    trapTypesList,
    typeLayersList,
  };
};

const TrapsPageFilters = () => {
  const {
    demandsList,
    employeesList,
    fetchAllComponentsData,
    filters,
    handleFiltersChange,
    handleTypeLayerChange,
    isTrapsListLoading,
    teamsList,
    territorializationsList,
    trapTypesList,
    typeLayersList,
  } = useTrapsPageFilters();

  return (
    <section>
      <Row className="mb-4">
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Demanda</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={true}
            value={filters.demand}
            options={demandsList}
            onChange={(e) =>
              handleFiltersChange(
                "demand",
                !e
                  ? {
                      label: "Todas",
                      value: "",
                    }
                  : e
              )
            }
            isDisabled={demandsList.length === 0}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Equipe</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={true}
            value={filters.team}
            options={teamsList}
            onChange={(e) =>
              handleFiltersChange(
                "team",
                !e
                  ? {
                      label: "Todos",
                      value: "",
                    }
                  : e
              )
            }
            isDisabled={teamsList.length === 0}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Funcionário</span>
          <Select
            styles={selectComponentStyles}
            isMulti={true}
            placeholder={"Selecione"}
            isClearable={true}
            options={employeesList}
            value={filters.employees}
            onChange={(e) =>
              handleFiltersChange("employees", !e || e.length === 0 ? [] : e)
            }
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            menuPortalTarget={document.body}
            isDisabled={employeesList.length === 0}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Data fim</span>
          <input
            type="date"
            name="endDate"
            id="endDate"
            className="form-control"
            value={filters.endDate}
            onChange={({ target }) =>
              handleFiltersChange("endDate", target.value)
            }
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Tipo de armadilha</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={true}
            value={filters.trapType}
            options={trapTypesList}
            onChange={(e) =>
              handleFiltersChange(
                "trapType",
                !e
                  ? {
                      label: "Selecione",
                      value: "",
                    }
                  : e
              )
            }
            isDisabled={trapTypesList.length === 0}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Territorialização</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={true}
            options={territorializationsList}
            value={filters.territorializations}
            isMulti={true}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            onChange={(e) =>
              handleFiltersChange(
                "territorializations",
                !e || e.length === 0 ? [] : e
              )
            }
            menuPortalTarget={document.body}
            isDisabled={territorializationsList.length === 0}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Delimitador de Área</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={true}
            options={typeLayersList}
            value={filters.typeLayers}
            isMulti={true}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            onChange={(e) => handleTypeLayerChange(e)}
            menuPortalTarget={document.body}
            isDisabled={typeLayersList.length === 0}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Status</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            options={trapsStatusesOptionsList}
            value={filters.statuses}
            isMulti={true}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            onChange={(e) =>
              handleFiltersChange("statuses", !e || e.length === 0 ? [] : e)
            }
            menuPortalTarget={document.body}
            isDisabled={trapsStatusesOptionsList.length === 0}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Situação por cores</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            options={trapsColorStatusesOptionsList}
            value={filters.colorStatuses}
            isMulti={true}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            onChange={(e) =>
              handleFiltersChange(
                "colorStatuses",
                !e || e.colorStatuses === 0 ? [] : e
              )
            }
            menuPortalTarget={document.body}
            isDisabled={trapsColorStatusesOptionsList.length === 0}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="col-md-4 visit-responsive-filter"></Col>
        <Col
          className="col-md-4 visit-responsive-filter"
          style={{ textAlign: "center" }}
        >
          <span
            className="text-white"
            style={{ fontSize: "14px", fontWeight: "bold" }}
          >
            Semana epidemiológica atual: {getTodayEpidemiologicalWeek()}
          </span>
        </Col>
        <Col className="col-md-4 visit-responsive-filter"></Col>
      </Row>
      <Row className="mb-2">
        <Col className="col-md-4 visit-responsive-filter"></Col>
        <Col className="col-md-4 visit-responsive-filter">
          {demandsList.length === 0 ||
          employeesList.length === 0 ||
          teamsList.length === 0 ||
          trapTypesList.length === 0 ||
          territorializationsList.length === 0 ? (
            <Button disabled={true} color="primary">
              Carregando preferencias...
            </Button>
          ) : isTrapsListLoading ? (
            <Button disabled={true} color="primary">
              <LoadingSpin color="#fff" size={17} />
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={() => fetchAllComponentsData(filters)}
            >
              Filtrar
            </Button>
          )}
        </Col>
        <Col className="col-md-4 visit-responsive-filter"></Col>
      </Row>
    </section>
  );
};

export default TrapsPageFilters;
