import React from "react";

import {
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Card, CardContent, CardFooter, CardHeader } from "../../Cards/Card";

const BarChartGraphic = ({
  bars,
  customTooltip,
  data,
  footerText,
  headerText,
  hideDownloadButton,
  hideExpandButton,
  keyToConsiderOnDomain,
  xAxisDataKey,
  xAxisLabel,
  yAxisLabel,
  showLegend = true,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleModal = () => {
    setIsModalOpen((previousValue) => !previousValue);
  };

  const styles = {
    modalCol: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "0.7rem",
      height: `${screenHeight - 300}px`,
    },
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    fullScreenStyle: {
      maxWidth: "100%",
      width: "100vw",
      height: "100vh",
      margin: "0",
      top: "0",
    },
    cardFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "16px",
    },
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal()}
        style={styles.fullScreenStyle}
      >
        <ModalHeader toggle={() => toggleModal()}>
          Visualização em tela cheia
        </ModalHeader>
        <ModalBody style={{ height: `${screenHeight - 150}px` }}>
          <Card>
            <CardHeader
              dataToDownload={data}
              headerText={headerText}
              showExpandButton={!hideExpandButton ? true : false}
              showButtonsTooltips={false}
              handleExpandButtonClick={toggleModal}
              showDownloadDataButton={!hideDownloadButton}
            />
            <CardContent>
              <Row>
                <Col style={styles.modalCol}>
                  <BarGraphic
                    bars={bars}
                    customTooltip={customTooltip}
                    data={data}
                    keyToConsiderOnDomain={keyToConsiderOnDomain}
                    xAxisDataKey={xAxisDataKey}
                    xAxisLabel={xAxisLabel}
                    yAxisLabel={yAxisLabel}
                    showLegend={showLegend}
                  />
                </Col>
              </Row>
            </CardContent>
            <CardFooter style={styles.cardFooter}>
              <div>{footerText}</div>
              <img
                style={{ maxWidth: "100px", opacity: 0.5 }}
                src="/static/media/vitec.9bd71d52.png"
                alt="Logo do Vitec"
              />
            </CardFooter>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleModal()}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <Card>
        <CardHeader
          dataToDownload={data}
          headerText={headerText}
          showExpandButton={!hideExpandButton ? true : false}
          showButtonsTooltips={true}
          handleExpandButtonClick={toggleModal}
          showDownloadDataButton={!hideDownloadButton}
        />
        <CardContent>
          <Row>
            <Col style={styles.col}>
              <BarGraphic
                bars={bars}
                customTooltip={customTooltip}
                data={data}
                keyToConsiderOnDomain={keyToConsiderOnDomain}
                xAxisDataKey={xAxisDataKey}
                xAxisLabel={xAxisLabel}
                yAxisLabel={yAxisLabel}
                showLegend={showLegend}
              />
            </Col>
          </Row>
        </CardContent>
        <CardFooter style={styles.cardFooter}>
          <div>
            {footerText}
          </div>
          <img
            style={{ maxWidth: "100px", opacity: 0.5 }}
            src="/static/media/vitec.9bd71d52.png"
            alt="Logo do Vitec"
          />
        </CardFooter>
      </Card>
    </>
  );
};

const BarGraphic = ({
  bars,
  customTooltip,
  data,
  keyToConsiderOnDomain,
  xAxisDataKey,
  xAxisLabel,
  yAxisLabel,
  showLegend,
}) => {
  const [hightestValue, setHightestValue] = React.useState(null);
  const [ticks, setTicks] = React.useState([]);

  React.useEffect(() => {
    if (!data || data.length === 0 || !keyToConsiderOnDomain) return;

    let hightestValueToSet = 0;

    data.forEach((item) => {
      const actualValue = +item[keyToConsiderOnDomain];

      if (hightestValueToSet < actualValue) hightestValueToSet = actualValue;
    });

    const tickInterval = Math.ceil(hightestValueToSet / 5);
    const newTicks = [];

    for (let i = 0; i <= hightestValueToSet; i += tickInterval)
      newTicks.push(i);

    newTicks.push(hightestValueToSet);

    setTicks(newTicks);
    setHightestValue(hightestValueToSet);
  }, [data, keyToConsiderOnDomain]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis label={xAxisLabel} dataKey={xAxisDataKey} />
        {keyToConsiderOnDomain ? (
          <YAxis label={yAxisLabel} domain={[0, hightestValue]} ticks={ticks} />
        ) : (
          <YAxis label={yAxisLabel} />
        )}
        <Tooltip content={!customTooltip ? null : customTooltip} />
        {showLegend ? <Legend /> : null}
        {!bars || bars.length === 0 ? null : bars.map((bar) => bar)}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartGraphic;
