import React from "react";
import { Col, Row } from "reactstrap";
import PieChartGraphic from "../../../components/Graphics/Pie/PieChartGraphic";
import LineChartGraphic from "../../../components/Graphics/Line/LineChartGraphic";
import { Line } from "recharts";
import moment from "moment";

const LoginReportPageGraphicsWrapper = ({ data, filtersValues }) => {
  const [formatedDataForPieChart, setFormatedDataForPieChart] = React.useState(
    []
  );
  const [formatedDataForLineChart, setFormatedDataForLineChart] =
    React.useState([]);

  const beginDateFormated =
    filtersValues &&
    filtersValues.beginDate &&
    moment(filtersValues.beginDate).format("DD/MM/YYYY");
  const finalDateFormated =
    filtersValues &&
    filtersValues.finalDate &&
    moment(filtersValues.finalDate).format("DD/MM/YYYY");

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    let appLogins = 0;
    let webLogins = 0;

    data.forEach(({ authenticatedOnApp }) => {
      if (authenticatedOnApp) appLogins++;
      else webLogins++;
    });

    const formatedDataForPieGraphic = [
      {
        loginType: "Aplicativo",
        quantity: appLogins,
      },
      {
        loginType: "WEB",
        quantity: webLogins,
      },
    ];

    setFormatedDataForLineChart(formatDataForLineChart(data));

    setFormatedDataForPieChart(formatedDataForPieGraphic);
  }, [data]);

  const formatDataForLineChart = (data) => {
    if (!data || data.length === 0) return;

    const datesMap = new Map();

    data.forEach(({ authenticatedOnApp, createAt }) => {
      const actualDate = moment(createAt)
        .subtract(3, "hours")
        .format("DD/MM/YYYY");

      if (!datesMap.has(actualDate)) {
        datesMap.set(actualDate, {
          date: actualDate,
          Aplicativo: 0,
          WEB: 0,
        });
      }

      const newValues = datesMap.get(actualDate);

      if (authenticatedOnApp) newValues["Aplicativo"]++;
      else newValues["WEB"]++;
    });

    const result = Array.from(datesMap.values());

    return result;
  };

  return (
    <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
      <Col className="mb-6 mb-xl-0" xl="6">
        <PieChartGraphic
          data={formatedDataForPieChart}
          customTooltip={<CustomTooltip />}
          dataKey="quantity"
          headerText={`Logins Aplicativo x WEB em ${beginDateFormated} a ${finalDateFormated}`}
          legendsKey="loginType"
        />
      </Col>
      <Col className="mb-6 mb-xl-0" xl="6">
        <LineChartGraphic
          data={formatedDataForLineChart}
          headerText={`Logins por Dia em ${beginDateFormated} a ${finalDateFormated}`}
          lines={[
            <Line type="monotone" dataKey="Aplicativo" stroke="#82ca9d" />,
            <Line type="monotone" dataKey="WEB" stroke="#cca847" />,
          ]}
          xAxisDataKey="date"
        />
      </Col>
    </Row>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p className="label" style={{ fontWeight: "bold" }}>
          {data["loginType"]}: {data["quantity"]}
        </p>
      </div>
    );
  }

  return null;
};

export default LoginReportPageGraphicsWrapper;
