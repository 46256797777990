import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "../FormManutencao.css";
import { toast } from "react-toastify";
import CsvDownloadButton from "react-json-to-csv";
import {
  getTrapByNumber,
  postTrap,
  postTrapHistory,
} from "../../../../services/api/Trap";
import { postVigilanciaEntomologica } from "../../../../services/api/VigilanciaEntomologica";
import UninstallAdiFormPrintModal from "../../../../components/Modal/WebForms/UninstallAdiFormPrintModal";
import {
  checkIfRequiredFieldsAreEmpty,
  getAddressInformations,
} from "../../../../services/utils/WebForms/validations";
import HeaderFormInputs from "../HeaderFormInputs";
import { getEntomologicVigilanceObjectForUninstallAdi } from "../../../../services/utils/WebForms/EntomologicVigilance";
import {
  getTrapHistoryObject,
  getTrapToPostObject,
} from "../../../../services/utils/WebForms/Trap";
import FooterFormButtons from "../FooterFormButtons";

const UninstallAdiForm = ({ employeesOptions, organizationId }) => {
  const [headerInputs, setHeaderInputs] = React.useState({
    uvisName: {
      label: "Selecione a UVIS",
      value: "",
    },
    administrativeDistrict: {
      label: "Selecione o Distrito Administrativo",
      value: "",
    },
    sanitaryDistrict: "",
    environmentalSector: "",
    block: "",
    teamsAndEmployees: [],
    activityDate: "",
  });
  const [tableRowsData, setTableRowsData] = React.useState([
    { ...getTableRowsObject() },
  ]);
  const [isSelectModalOpen, setIsSelectModalOpen] = React.useState(false);
  const [selectValueInsideModal, setSelectValueInsideModal] =
    React.useState("");
  const [selectedInputData, setSelectedInputData] = React.useState(null);
  const [isPrintFormModalOpen, setIsPrintFormModalOpen] = React.useState(false);
  const [isSubmitingForm, setIsSubmitingForm] = React.useState(false);

  const MAX_ROWS_PER_FORM = 20;
  const MIN_ROWS_PER_FORM = 1;
  const EMPTY_FIELD = "";

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function getTableRowsObject() {
    return {
      address: "",
      addressNumber: "",
      addressComplement: "",
      propertyType: "",
      responsablePersonName: "",
      responsablePersonCellPhoneNumber: "",
      visitStatus: "",
      trapNumber: "",
      isTrapInUsableConditions: "sim",
      whyIsTrapInUnusableConditions: "",
      whyIsTrapInUnusableConditionsSelectedOptions: "",
      wasSampleCreated: "não",
      sampleNumber: "",
      sampleType: "",
      changedLabelNumber: "não",
      newLabelNumber: "",
      whyTrapWasRemoved: "",
    };
  }

  const handleHeaderInputsChange = (inputName, inputValue) => {
    const newHeaderInputs = { ...headerInputs };
    newHeaderInputs[inputName] = inputValue;

    setHeaderInputs(newHeaderInputs);
  };

  const toggleSelectModal = () => {
    setIsSelectModalOpen(!isSelectModalOpen);
  };

  const openSelectModal = () => {
    setIsSelectModalOpen(true);
  };

  const handleSelectModalClose = () => {
    setIsSelectModalOpen(false);
    setSelectedInputData(null);
    setSelectValueInsideModal("");
  };

  const handleInputChange = (inputName, inputValue, rowIndex) => {
    const newTableRow = [...tableRowsData];
    newTableRow[rowIndex][inputName] = inputValue;

    setTableRowsData(newTableRow);
  };

  const handleRepeatAddress = () => {
    if (tableRowsData.length >= MAX_ROWS_PER_FORM) {
      toast.error("Não é possível adicionar mais que 20 linhas por formulário");
      return;
    }

    setTableRowsData((previousRows) => {
      const lastRow = previousRows[previousRows.length - 1];
      const newRow = { ...getTableRowsObject(), address: lastRow.address };
      return [...previousRows, newRow];
    });
  };

  const handleAddNewAddress = () => {
    if (tableRowsData.length >= MAX_ROWS_PER_FORM) {
      toast.error("Não é possível adicionar mais que 20 linhas por formulário");
      return;
    }

    setTableRowsData((previousRows) => {
      return [...previousRows, { ...getTableRowsObject() }];
    });
  };

  const handleDeleteRow = (rowIndex) => {
    if (tableRowsData.length === MIN_ROWS_PER_FORM) {
      toast.error("Não é possível ter menos que uma linha por formulário");
      return;
    }

    setTableRowsData((previousRows) => {
      const newTableRows = [...previousRows];
      newTableRows.splice(rowIndex, 1);

      return newTableRows;
    });
  };

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === "r") {
      event.preventDefault();
      handleRepeatAddress();

      return;
    }

    if (event.ctrlKey && event.key === "a") {
      event.preventDefault();
      handleAddNewAddress();

      return;
    }
  };

  const handleSelectClick = (inputName, inputValue, indexRow) => {
    setSelectedInputData({
      inputName,
      inputValue,
      indexRow,
    });
    openSelectModal();
  };

  const handleSelectChangeInsideModal = (newValue) => {
    setSelectValueInsideModal(newValue);
    handleInputChange(
      selectedInputData.inputName,
      newValue,
      selectedInputData.indexRow
    );
  };

  const handleSearchTrapClick = async (trapNumber, rowIndex) => {
    if (!trapNumber && !rowIndex) return;

    const { data, status } = await getTrapByNumber(trapNumber);

    if (status !== 200) {
      toast.error(
        `A armadilha de número '${trapNumber}' não foi encontrado na base de dados. Caso prossiga com o preenchimento e o envio dos dados do formulário, a armadilha será instalada e passará por manutenção ao mesmo tempo.`
      );

      return;
    }

    if (data.trapTypeName !== getTrapTypeName["ADI"]) {
      toast.error(
        `A armadilha de número '${trapNumber}' existe na base de dados, entretanto, trata-se de uma ${
          getTrapTypeSurname[data.trapTypeName]
        }. Esse formulário é para a desinstalação somente de ADI`
      );

      return;
    } else
      toast.success(
        `Dados da armadilha ADI de número '${trapNumber}' encontrados com sucesso.`
      );

    const [trapAddressName, trapAddressNumber] = data.address.split(",");

    handleInputChange("address", trapAddressName, rowIndex);
    handleInputChange("addressNumber", trapAddressNumber, rowIndex);
  };

  const checkIfDependentSelectsAreEmpty = (tableRows) => {
    let errorMessage = "";

    for (let i = 0; i < tableRows.length; i++) {
      const {
        isTrapInUsableConditions,
        whyIsTrapInUnusableConditions,
        whyIsTrapInUnusableConditionsSelectedOptions,
        trapWasRead,
        trapWasReadSelectedOptions,
        wereFoundLarvaeInTrap,
        aliveLarvaeNumberSelectedInTrap,
        deadLarvaeNumberSelectedInTrap,
        wereFoundPupaInTrap,
        alivePupaNumberSelectedInTrap,
        deadPupaNumberSelectedInTrap,
        noticedMosquitoInTrap,
        aliveMosquitoNumberSelectedInTrap,
        deadMosquitoNumberSelectedInTrap,
        wasSampleCreated,
        sampleNumber,
        sampleType,
        whyTrapWasRemoved,
      } = tableRows[i];

      if (
        isTrapInUsableConditions === "não" &&
        whyIsTrapInUnusableConditions === EMPTY_FIELD
      ) {
        errorMessage = `Informe o motivo de a ARMADILHA NÃO estar em CONDIÇÕES de uso na linha ${
          i + 1
        }`;
        break;
      }
      if (
        whyIsTrapInUnusableConditions === "Peça com defeito" &&
        whyIsTrapInUnusableConditionsSelectedOptions === EMPTY_FIELD
      ) {
        errorMessage = `Informe a peça que está com DEFEITO na ARMADILHA na linha ${
          i + 1
        }`;
        break;
      }
      if (trapWasRead === "sim" && trapWasReadSelectedOptions === "") {
        errorMessage = `Informe a atividade de MANUTENÇÃO realizada na ARMADILHA na linha ${
          i + 1
        }`;
        break;
      }
      if (
        (wereFoundLarvaeInTrap === "sim" &&
          aliveLarvaeNumberSelectedInTrap === "999") ||
        (wereFoundLarvaeInTrap === "sim" &&
          deadLarvaeNumberSelectedInTrap === "999")
      ) {
        errorMessage = `Informe a QUANTIDADE aproximada de LARVAS VIVAS e MORTAS na linha ${
          i + 1
        }`;
        break;
      }
      if (
        (wereFoundPupaInTrap === "sim" &&
          alivePupaNumberSelectedInTrap === "999") ||
        (wereFoundPupaInTrap === "sim" &&
          deadPupaNumberSelectedInTrap === "999")
      ) {
        errorMessage = `Informe a QUANTIDADE aproximada de PUPAS VIVAS e MORTAS na linha ${
          i + 1
        }`;
        break;
      }
      if (
        (noticedMosquitoInTrap === "sim" &&
          aliveMosquitoNumberSelectedInTrap === "999") ||
        (noticedMosquitoInTrap === "sim" &&
          deadMosquitoNumberSelectedInTrap === "999")
      ) {
        errorMessage = `Informe a QUANTIDADE aproximada de MOSQUITOS VIVOS e MORTOS na linha ${
          i + 1
        }`;
        break;
      }
      if (
        (wasSampleCreated === "sim" && sampleNumber === EMPTY_FIELD) ||
        (wasSampleCreated === "sim" && sampleType === EMPTY_FIELD)
      ) {
        errorMessage = `Informe o NÚMERO e o TIPO da AMOSTRA na linha ${i + 1}`;
        break;
      }
      if (
        (whyTrapWasRemoved === EMPTY_FIELD)
      ) {
        errorMessage = `Informe o MOTIVO da DESINTALAÇÃO na linha ${i + 1}`;
        break;
      }
    }

    return errorMessage !== "" ? errorMessage : false;
  };

  const checkIfTrapAreAlreadyInstalled = async (trapNumber) => {
    const { status: responseStatus } = await getTrapByNumber(trapNumber);

    return responseStatus === 200 ? true : false;
  };

  const checkIfAtLeastOneTrapIsNotInstalled = async (tableRows) => {
    let trapNotInstalledError;

    for (const tableRow of tableRows) {
      const { trapNumber } = tableRow;

      const isTrapInstalled = await checkIfTrapAreAlreadyInstalled(trapNumber);

      if (!isTrapInstalled) {
        trapNotInstalledError = `A ARMADILHA de número '${trapNumber}' NÃO está INSTALADA. Verifique se o número preenchido está correto ou faça sua instalação antes de realizar a manutenção.`;
        break;
      }
    }

    return trapNotInstalledError;
  };

  const handleSubmit = async () => {
    let occurredErrorWhileSubmitingForm = false;

    const requiredFieldsErrorMessage = checkIfRequiredFieldsAreEmpty(
      headerInputs,
      tableRowsData,
      organizationId
    );
    const dependentSelectsErrorMessage =
      checkIfDependentSelectsAreEmpty(tableRowsData);

    if (requiredFieldsErrorMessage) {
      toast.error(requiredFieldsErrorMessage);
      return;
    }

    if (dependentSelectsErrorMessage) {
      toast.error(dependentSelectsErrorMessage);
      return;
    }

    const trapNotInstalledErrorMessage =
      await checkIfAtLeastOneTrapIsNotInstalled(tableRowsData);

    if (trapNotInstalledErrorMessage) {
      toast.error(trapNotInstalledErrorMessage);
      return;
    }

    setIsSubmitingForm(true);

    for (const tableRow of tableRowsData) {
      try {
        const { geometry } = await getAddressInformations(
          tableRow.address,
          tableRow.addressNumber
        );
        const latitude =
          geometry && geometry.location.lat
            ? geometry.location.lat.toFixed(7)
            : null;
        const longitude =
          geometry && geometry.location.lng
            ? geometry.location.lng.toFixed(7)
            : null;

          const trapInformations = {
            latitude,
            longitude,
            trapName: "ADI",
            install: false,
            maintenance: false,
            uninstall: tableRow.whyTrapWasRemoved !== "" ? true : false,
          };

        const entomologicVigilanceObject =
          getEntomologicVigilanceObjectForUninstallAdi(
            tableRow,
            headerInputs,
            trapInformations
          );

        const { data: entomologicVigilanceRequestResponse } =
          await postVigilanciaEntomologica(entomologicVigilanceObject);

        const trapToPostObject = getTrapToPostObject(
          tableRow,
          headerInputs,
          trapInformations
        );

        const { data: trapRequestReponse } = await postTrap(trapToPostObject);

        const trapHistoryObject = getTrapHistoryObject(
          tableRow,
          headerInputs,
          trapInformations,
          trapRequestReponse.id,
          entomologicVigilanceRequestResponse.vigilanciaId
        );

        await postTrapHistory(trapHistoryObject);
      } catch (error) {
        occurredErrorWhileSubmitingForm = true;
        console.error(error);

        setIsSubmitingForm(false);
        toast.error(
          "Ocorreu um erro inesperado ao enviar os dados do formulário. Salve as digitações clicando no botão 'Baixar CSV das digitações' e entre em contato com nossa equipe"
        );
      }
    }

    if (!occurredErrorWhileSubmitingForm) {
      toast.success("Dados do formulário enviados com sucesso.");
      setTableRowsData([{ ...getTableRowsObject() }]);
      setHeaderInputs({
        uvisName: "",
        administrativeDistrict: "",
        environmentalSector: "",
        block: "",
        teamsAndEmployees: [],
        activityDate: "",
      });
    }

    setIsSubmitingForm(false);
  };

  const getSelectName = {
    propertyType: "Selecione o tipo de imóvel",
    visitStatus: "Selecione o status da visita",
    whyIsTrapInUnusableConditions:
      "Se a armadilha NÃO está em condições de uso, informe o motivo",
    whyIsTrapInUnusableConditionsSelectedOptions:
      "Se o motivo for 'ACU5 - Peça com defeito', informe o componente comprometido",
    sampleType: "Se foi gerada amostra, selecione seu tipo",
    whyTrapWasRemoved: "Selecione o motivo da desinstalação",
  };

  const getSelectOptions = {
    propertyType: [
      {
        label: "Selecione uma opção",
        value: "",
      },
      {
        label: "R - Residência",
        value: "Residência",
      },
      {
        label: "C - Comércio",
        value: "Comércio",
      },
      {
        label: "TB - Terreno Baldio",
        value: "Terreno Baldio",
      },
      {
        label: "PE - Ponto Estratégico",
        value: "Ponto Estratégico",
      },
      {
        label: "PP - Próprio Público",
        value: "Próprio Público",
      },
      {
        label: "IE - Imóvel Especial",
        value: "Imóvel Especial",
      },
      {
        label: "VP - Via Pública",
        value: "Via Pública",
      },
      {
        label: "O - Outros",
        value: "Outros",
      },
    ],
    visitStatus: [
      {
        label: "Selecione uma opção",
        value: "",
      },
      {
        label: "T - Trabalhado",
        value: "Aberto",
      },
      {
        label: "F - Fechado",
        value: "Fechado",
      },
      {
        label: "R - Recusa",
        value: "Recusa",
      },
      {
        label: "O - Orientação",
        value: "Orientação",
      },
      {
        label: "EN - Endereço Não Encontrado",
        value: "Endereço Não Encontrado",
      },
    ],
    whyIsTrapInUnusableConditions: [
      {
        label: "Selecione uma opção",
        value: "",
      },
      {
        label: "FPA - Faltam partes da armadilha",
        value: "Faltam partes da armadilha",
      },
      {
        label: "ANL - Armadilha não localizada no local",
        value: "Armadilha não localizada no local",
      },
      {
        label: "E - Extraviada",
        value: "Extraviada",
      },
      {
        label: "AD - Armadilha danificada",
        value: "Armadilha danificada",
      },
      {
        label: "PD - Peça com defeito",
        value: "Peça com defeito",
      },
    ],
    whyIsTrapInUnusableConditionsSelectedOptions: [
      {
        label: "Selecione uma opção",
        value: "",
      },
      {
        label: "IT - Indicador de tempo",
        value: "Indicador de tempo",
      },
      {
        label: "T - Tampa",
        value: "Tampa",
      },
      {
        label: "F - Flutuador",
        value: "Flutuador",
      },
      {
        label: "B - Balde",
        value: "Balde",
      },
    ],
    sampleType: [
      {
        label: "Selecione uma opção",
        value: "",
      },
      {
        label: "AM1 - Água",
        value: "Água",
      },
      {
        label: "AM2 - Larva",
        value: "Larva",
      },
      {
        label: "AM3 - Pupa",
        value: "Pupa",
      },
      {
        label: "AM4 - Larva e Pupa",
        value: "Larva e Pupa",
      },
    ],
    whyTrapWasRemoved: [
      {
        label: "Selecione uma opção",
        value: "",
      },
      {
        label: "FPS - Fim do período de supressão de Aedes Aegypti",
        value: "Fim do período de supressão de Aedes Aegypti",
      },
      {
        label: "SPM - Solicitado pelo morador/responsável",
        value: "Solicitado pelo morador/responsável",
      },
      {
        label: "PAC - Presença constante de animais domésticos ou crianças",
        value: "Presença constante de animais domésticos ou crianças",
      },
      {
        label: "ADP - A armadilha foi danificada ou está faltando partes",
        value: "A armadilha foi danificada ou está faltando partes",
      },
      {
        label: "ADM - A armadilha foi descartada pelo morador/responsável",
        value: "A armadilha foi descartada pelo morador/responsável",
      },
    ],
  };

  const getTrapTypeName = {
    ADI: "armadilhaDisseminadoraInseticida",
    OVITRAMPA: "armadilhaOvos",
    "BG-GAT": "armadilhaMosquitoAdulto2",
  };

  const getTrapTypeSurname = {
    armadilhaDisseminadoraInseticida: "ADI",
    armadilhaOvos: "OVITRAMPA",
    armadilhaMosquitoAdulto2: "BG-GAT",
  };

  return (
    <>
      <Modal
        size="lg"
        fade
        isOpen={isSelectModalOpen}
        toggle={() => toggleSelectModal()}
        onClosed={() => handleSelectModalClose()}
      >
        <ModalHeader>
          <span>Dados da visita</span>
        </ModalHeader>
        <ModalBody>
          {selectedInputData && (
            <>
              <h1 style={{ textAlign: "center" }}>
                {getSelectName[selectedInputData.inputName]}
              </h1>
              <select
                name="receba"
                id="receba"
                className="form-control"
                value={selectValueInsideModal}
                onChange={({ target }) =>
                  handleSelectChangeInsideModal(target.value)
                }
              >
                {getSelectOptions[selectedInputData.inputName].map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="row">
            <div className="col-6" style={{ maxWidth: "100%" }}>
              <button
                className="btn btn-primary"
                onClick={() => handleSelectModalClose()}
                style={{ cursor: "pointer" }}
              >
                FECHAR
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      <UninstallAdiFormPrintModal
        isModalOpen={isPrintFormModalOpen}
        setIsModalOpen={setIsPrintFormModalOpen}
        trapTypeName={"ADI"}
      />
      <HeaderFormInputs
        headerInputs={headerInputs}
        handleHeaderInputsChange={handleHeaderInputsChange}
        employeesOptions={employeesOptions}
        organizationId={organizationId}
      />
      <Row className="mt-4" style={{ justifyContent: "center" }}>
        <Col className="col-3">
          <div className="filter-container container">
            <button
              onClick={() => setIsPrintFormModalOpen(true)}
              className="btn btn-primary"
            >
              <i className="fa fa-file" style={{ marginRight: ".5rem" }}></i>
              Gerar Formulário Vazio
            </button>
          </div>
        </Col>
        <Col className="col-3 ">
          <CsvDownloadButton
            className="btn btn-primary"
            data={tableRowsData}
            filename={"manutencao"}
          >
            <i className="fa fa-download" /> Baixar CSV das digitações
          </CsvDownloadButton>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div>
            <table
              className="table_form_vistoria"
              style={{
                borderCollapse: "collapse",
                width: "100%",
                fontSize: 13,
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "#4f8bb3",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th
                    style={{ width: "10px" }}
                    rowSpan="2"
                    className="vertical-header"
                  >
                    ORDEM
                  </th>
                  <th colSpan="5">ENDEREÇO</th>
                  <th colSpan="2">
                    RESPONSÁVEL
                    <br /> (Morador)
                  </th>
                  <th colSpan="20">ARMADILHA</th>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#4f8bb3",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>(*)Número da armadilha</th>
                  <th style={{ minWidth: "150px" }}>(*)Logradouro</th>
                  <th>(*)Número</th>
                  <th>Complemento</th>
                  <th>
                    (*)Tipo do <br /> Imóvel
                  </th>
                  <th>Nome</th>
                  <th>Telefone</th>
                  <th className="vertical-header align-left">
                    Selecione o status da visita
                  </th>
                  <th className="vertical-header align-left">
                    A armadilha está em condições de uso? <br />
                  </th>
                  <th className="vertical-header align-left">
                    Informe o motivo de NÃO estar em condições de uso
                  </th>
                  <th className="vertical-header align-left">
                    Se for "Peça com defeito", informe o componente comprometido
                  </th>
                  <th className="vertical-header align-left">
                    Foi gerada amostra?
                  </th>
                  <th className="vertical-header align-left">
                    Informe o número da amostra
                  </th>
                  <th className="vertical-header align-left">
                    Selecione o tipo de amostra
                  </th>
                  <th className="vertical-header align-left">
                    Foi trocada etiqueta?
                  </th>
                  <th className="vertical-header align-left" style={{ minWidth: "100px" }}>
                    Número da nova etiqueta
                  </th>
                  <th className="vertical-header align-left">
                    Motivo da desinstalação
                  </th>
                  <th
                    rowSpan="2"
                    className="vertical-header"
                    style={{ width: "50px" }}
                  >
                    EXCLUIR
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableRowsData.map((row, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "lightgrey" : "darkgrey",
                    }}
                  >
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={index + 1}
                        style={{ textAlign: "center" }}
                      />
                    </td>
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          className="input_coluna"
                          style={{ width: "140px", flexGrow: 1 }}
                          type="text"
                          value={row.trapNumber}
                          onChange={({ target }) =>
                            handleInputChange("trapNumber", target.value, index)
                          }
                        />
                        <button
                          className="btn btn-primary"
                          style={{ padding: "5px", width: "50px" }}
                          onClick={() =>
                            handleSearchTrapClick(row.trapNumber, index)
                          }
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </td>
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          className="input_coluna"
                          type="text"
                          value={row.address}
                          onChange={({ target }) =>
                            handleInputChange("address", target.value, index)
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.addressNumber}
                        onChange={({ target }) =>
                          handleInputChange(
                            "addressNumber",
                            target.value,
                            index
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.addressComplement}
                        onChange={({ target }) =>
                          handleInputChange(
                            "addressComplement",
                            target.value,
                            index
                          )
                        }
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <select
                        name="propertyType"
                        id="propertyType"
                        value={row.propertyType}
                        onClick={({ target }) =>
                          handleSelectClick("propertyType", target.value, index)
                        }
                      >
                        <option value=""></option>
                        <option value="Residência">R</option>
                        <option value="Comércio">C</option>
                        <option value="Terreno Baldio">TN</option>
                        <option value="Ponto Estratégico">PE</option>
                        <option value="Próprio Público">PP</option>
                        <option value="Imóvel Especial">IE</option>
                        <option value="Via Pública">VP</option>
                        <option value="Outros">O</option>
                      </select>
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.responsablePersonName}
                        onChange={({ target }) =>
                          handleInputChange(
                            "responsablePersonName",
                            target.value,
                            index
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.responsablePersonCellPhoneNumber}
                        onChange={({ target }) =>
                          handleInputChange(
                            "responsablePersonCellPhoneNumber",
                            target.value,
                            index
                          )
                        }
                      />
                    </td>
                    <td>
                      <select
                        name="visitStatus"
                        id="visitStatus"
                        value={row.visitStatus}
                        onClick={({ target }) =>
                          handleSelectClick("visitStatus", target.value, index)
                        }
                      >
                        <option value=""></option>
                        <option value="Aberto">T</option>
                        <option value="Fechado">F</option>
                        <option value="Recusa">R</option>
                        <option value="Orientação">O</option>
                        <option value="Endereço Não Encontrado">EN</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="isTrapInUsableConditions"
                        id="isTrapInUsableConditions"
                        value={row.isTrapInUsableConditions}
                        onChange={({ target }) =>
                          handleInputChange(
                            "isTrapInUsableConditions",
                            target.value,
                            index
                          )
                        }
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado"
                            ? true
                            : false
                        }
                      >
                        <option value="sim">S</option>
                        <option value="não">N</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="whyIsTrapInUnusableConditions"
                        id="whyIsTrapInUnusableConditions"
                        value={row.whyIsTrapInUnusableConditions}
                        onClick={({ target }) =>
                          handleSelectClick(
                            "whyIsTrapInUnusableConditions",
                            target.value,
                            index
                          )
                        }
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado" ||
                          tableRowsData[index].isTrapInUsableConditions ===
                            "sim"
                            ? true
                            : false
                        }
                      >
                        <option value=""></option>
                        <option value="Faltam partes da armadilha">FPA</option>
                        <option value="Armadilha não localizada no local">
                          ANL
                        </option>
                        <option value="Extraviada">E</option>
                        <option value="Armadilha danificada">AD</option>
                        <option value="Peça com defeito">PD</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="whyIsTrapInUnusableConditionsSelectedOptions"
                        id="whyIsTrapInUnusableConditionsSelectedOptions"
                        value={row.whyIsTrapInUnusableConditionsSelectedOptions}
                        onClick={({ target }) =>
                          handleSelectClick(
                            "whyIsTrapInUnusableConditionsSelectedOptions",
                            target.value,
                            index
                          )
                        }
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado" ||
                          tableRowsData[index].whyIsTrapInUnusableConditions !==
                            "Peça com defeito"
                            ? true
                            : false
                        }
                      >
                        <option value=""></option>
                        <option value="Indicador de tempo">IT</option>
                        <option value="Tampa">T</option>
                        <option value="Flutuador">F</option>
                        <option value="Balde">B</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="wasSampleCreated"
                        id="wasSampleCreated"
                        value={row.wasSampleCreated}
                        onChange={({ target }) =>
                          handleInputChange(
                            "wasSampleCreated",
                            target.value,
                            index
                          )
                        }
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado"
                            ? true
                            : false
                        }
                      >
                        <option value="sim">S</option>
                        <option value="não">N</option>
                      </select>
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.sampleNumber}
                        onChange={({ target }) =>
                          handleInputChange("sampleNumber", target.value, index)
                        }
                        disabled={
                          tableRowsData[index].wasSampleCreated === "não"
                            ? true
                            : false
                        }
                      />
                    </td>
                    <td>
                      <select
                        name="sampleType"
                        id="sampleType"
                        value={row.sampleType}
                        onClick={({ target }) =>
                          handleSelectClick("sampleType", target.value, index)
                        }
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado" ||
                          tableRowsData[index].wasSampleCreated === "não"
                            ? true
                            : false
                        }
                      >
                        <option value=""></option>
                        <option value="Água">AM1</option>
                        <option value="Larva">AM2</option>
                        <option value="Pupa">AM3</option>
                        <option value="Larva e Pupa">AM4</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="changedLabelNumber"
                        id="changedLabelNumber"
                        value={row.changedLabelNumber}
                        className="select"
                        onChange={({ target }) => {
                          handleInputChange(
                            "changedLabelNumber",
                            target.value,
                            index
                          );
                        }}
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado"
                        }
                      >
                        <option value="sim">S</option>
                        <option value="não">N</option>
                      </select>
                    </td>
                    <td>
                      <input
                        className="input_coluna"
                        type="text"
                        value={row.newLabelNumber}
                        onChange={({ target }) =>
                          handleInputChange("newLabelNumber", target.value, index)
                        }
                        disabled={
                          tableRowsData[index].changedLabelNumber === "não"
                            ? true
                            : false
                        }
                      />
                    </td>
                    <td>
                      <select
                        name="whyTrapWasRemoved"
                        id="whyTrapWasRemoved"
                        value={row.whyTrapWasRemoved}
                        onClick={({ target }) =>
                          handleSelectClick(
                            "whyTrapWasRemoved",
                            target.value,
                            index
                          )
                        }
                        disabled={
                          tableRowsData[index].visitStatus === "" ||
                          tableRowsData[index].visitStatus === "Fechado" ||
                          tableRowsData[index].visitStatus === "Recusa" ||
                          tableRowsData[index].visitStatus ===
                            "Endereço Não Encontrado"
                            ? true
                            : false
                        }
                      >
                        <option value=""></option>
                        <option value="Fim do período de supressão de Aedes Aegypti">
                          FPS
                        </option>
                        <option value="Solicitado pelo morador/responsável">
                          SPM
                        </option>
                        <option value="Presença constante de animais domésticos ou crianças">
                          PAC
                        </option>
                        <option value="A armadilha foi danificada ou está faltando partes">
                          ADP
                        </option>
                        <option value="A armadilha foi descartada pelo morador/responsável">
                          ADM
                        </option>
                      </select>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <i
                        onClick={() => handleDeleteRow(index)}
                        style={{
                          color: "red",
                          fontSize: "21px",
                          cursor: "pointer",
                        }}
                        class="fa fa-times"
                        aria-hidden="true"
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <FooterFormButtons
              handleRepeatAddress={handleRepeatAddress}
              handleAddNewAddress={handleAddNewAddress}
              handleSubmit={handleSubmit}
              isSubmitingForm={isSubmitingForm}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h1 style={{ color: "#f75c5c" }}>
            Instruções para preenchimento do formulário
          </h1>
          <ul style={{ color: "white" }}>
            <li>
              Caso o formulário impresso NÃO possua a informação dos AGENTES que
              realizaram a MANUTENÇÃO da armadilha ou não encontre na lista de
              funcionários seu nome, selecione o funcionário "Não informado -
              RF: 13212312";
            </li>
            <li>
              Caso o formulário impresso NÃO possua o NÚMERO do ENDEREÇO,
              preencha com "S/N" no lugar do número;
            </li>
            <li>
              Caso o formulário impresso NÃO possua o TIPO DE IMÓVEL, selecione
              a opção "Outros (O)";
            </li>
            <li>
              Clicando no botão{" "}
              <i
                className="fa fa-arrow-left"
                style={{ color: "white", fontSize: "23px" }}
              ></i>{" "}
              que encontra-se no canto superior esquerdo da tela, é possível
              ocultar o menu lateral esquerdo com a listagem das telas, gerando
              mais espaço para o preenchimento e visualização das questões no
              formulário;
            </li>
            <li>
              Ao informar o número da armadilha e clicar no botão{"  "}
              <button
                class="btn btn-primary"
                style={{ padding: "5px", width: "50px" }}
              >
                <i class="fa fa-search"></i>
              </button>{" "}
              é possível buscar na base de dados as informações da armadilha.
              Caso o número informado exista, o sistema automaticamente preenche
              os dados do endereço;
            </li>
            <li>
              Apertando a combinação de teclas "CTRL + - (menos)", é possível
              reduzir o tamanho do zoom na página, gerando mais espaço para o
              preenchimento e visualização das questões no formulário;
            </li>
            <li>
              O formulário apenas aceitará a manutenção de armadilhas que já
              encontram-se instaladas na base de dados. Caso queira realizar o
              procedimento, procure pelo formulário de instalação de ADI;
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};

export default UninstallAdiForm;