import React from "react";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../components/Cards/Card";
import {
  Circle,
  FeatureGroup,
  LayersControl,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  Tooltip,
} from "react-leaflet";
//import * as turf from "@turf/turf";
import { polygon } from "@turf/turf";
import MarkerClusterGroup from "react-leaflet-markercluster";
import moment from "moment";
import { Badge, Button } from "reactstrap";
import { TrapsPageContext } from "../Contexts/trapsPageContext";
import { useContextSelector } from "use-context-selector";
import ReactTooltip from "react-tooltip";
import { Map } from "../../../../components/Maps/Map";
import Control from "react-leaflet-control";
import { MapContext } from "../Contexts/mapContext";
import useMapPoints from "../Hooks/useMapPoints";
import { fetchFilteredTraps } from "../../../../services/api/Trap";
import { getUserData } from "../../../../services/utils/auth";
import {
  ACTIVE_TRAP_STATUS,
  getPeriodsToSendOnRequest,
  getUsersIdsToSendOnRequest,
  GREEN_TRAP_STATUS,
  RED_TRAP_STATUS,
  trapBackgroundColorByStatusNumber,
  YELLOW_TRAP_STATUS,
} from "../../../../services/utils/globalFunctions";
import { toast } from "react-toastify";
import LoadingSpin from "react-loading-spin";
import { area } from "@turf/turf";
import { getTrapRange } from "../../../../constants/RangeTrapConstant";
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
} from "../../../../components/Accordion/Accordion";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import Draggable from "react-draggable";
import useMapOperatingRanges from "../Hooks/useMapOperatingRanges";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import useTerritorializationsList from "../../../../hooks/useTerritorializationsList";
import useTrapTypesList from "../../../../hooks/useTrapTypesList";
import useMap from "../Hooks/useMap";

const { BaseLayer, Overlay } = LayersControl;

const TrapsPageMapWrapperComponent = ({ groupPointsList }) => {
  const { territorializationsList } = useTerritorializationsList();
  const { trapTypesList } = useTrapTypesList();
  const { appliedFilters } = useAppliedFilters();
  const { filteredTrapPosition } = useMap();

  return (
    <section>
      <FloatingPolygonPopup trapTypesList={trapTypesList} />
      <Card className="mb-4">
        <CardHeader
          headerText={`Mapa de armadilhas`}
          showExpandButton={false}
          showDownloadDataButton={false}
          showDownloadImageButton={false}
        />
        <CardContent>
          <Map center={filteredTrapPosition}>
            <MapPointsLayersControl
              groupPointsList={groupPointsList}
              layerTitle="Tipo de Armadilha"
              defaultSelectedPointsGroupName={appliedFilters.trapType.label}
            />
            <MapPolygonsLayersControl
              data={territorializationsList}
              defaultSelectedTypeLayerName="UVIS"
              layerTitle="Delimitador de Área"
            />
            <RangeMapTrapTools />
            <FilterMapTrapTools trapTypesList={trapTypesList} />
            <TrapsOperatingRangeWrapper />
          </Map>
        </CardContent>
        <CardFooter>
          <div></div>
          <img
            style={{ maxWidth: "100px", opacity: 0.5 }}
            src="/static/media/vitec.9bd71d52.png"
            alt="Logo do Vitec"
          />
        </CardFooter>
      </Card>
    </section>
  );
};

const MapPolygonsLayersControl = ({
  data,
  layerTitle,
  defaultSelectedTypeLayerName = "",
}) => {
  const [typeLayersPolygons, setTypeLayersPolygons] = React.useState([]);

  const togglePolygonPopup = useContextSelector(
    MapContext,
    (context) => context.togglePolygonPopup
  );

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const typeLayersPolygonsGroup = [];

    data.forEach(({ name, territorializations }) => {
      const typeLayerData = {
        name,
        polygons: [],
      };

      territorializations.forEach((territorialization) => {
        const turfPolygon = polygon([
          territorialization.coordinates.map(({ x, y }) => [x, y]),
        ]);

        turfPolygon.name = territorialization.name;
        turfPolygon.territorialization = territorialization;

        typeLayerData.polygons.push(turfPolygon);
      });

      typeLayersPolygonsGroup.push(typeLayerData);
    });

    setTypeLayersPolygons(typeLayersPolygonsGroup);
  }, [data]);

  return (
    <LayersControl position="topright">
      {layerTitle !== "" && (
        <BaseLayer checked name={layerTitle}>
          <TileLayer attribution="" url="" />
        </BaseLayer>
      )}
      {typeLayersPolygons &&
        typeLayersPolygons.length > 0 &&
        typeLayersPolygons.map(({ name, polygons }, index) => {
          return (
            <Overlay
              checked={defaultSelectedTypeLayerName === name}
              name={name}
              key={`${name}-overlay-${index}`}
            >
              <FeatureGroup
                fillOpacity={0.3}
                name={name}
                key={`${name}-feature-${index}`}
              >
                {polygons &&
                  polygons.length > 0 &&
                  polygons.map((polygon, index) => {
                    const { geometry, name } = polygon;

                    const coordinates = geometry.coordinates[0].map(
                      ([latitude, longitude]) => [longitude, latitude]
                    );

                    return (
                      <Polygon
                        key={`${name}-polygon-${index}`}
                        color={"#000000"}
                        fillColor={"transparent"}
                        opacity={1}
                        positions={coordinates}
                        onclick={() => togglePolygonPopup(polygon)}
                      >
                        {name ? <Tooltip sticky>{name}</Tooltip> : null}
                      </Polygon>
                    );
                  })}
              </FeatureGroup>
            </Overlay>
          );
        })}
    </LayersControl>
  );
};

const MapPointsLayersControl = ({
  groupPointsList,
  layerTitle,
  defaultSelectedPointsGroupName = "",
}) => {
  const setSelectedTrapNumber = useContextSelector(
    TrapsPageContext,
    (context) => context.setSelectedTrapNumber
  );
  const toggleEditTrapModal = useContextSelector(
    TrapsPageContext,
    (context) => context.toggleEditTrapModal
  );
  const {
    filteredGroupsPointsList: groupsPointsFromContext,
    removePointsCluster,
  } = useMapPoints();

  return (
    <LayersControl position="topright">
      <BaseLayer checked name={layerTitle}>
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {groupsPointsFromContext && groupsPointsFromContext.length > 0
        ? groupsPointsFromContext.map(({ name, points }, index) => {
            return (
              <Overlay
                key={index}
                name={name}
                checked={name === defaultSelectedPointsGroupName}
              >
                <MarkerClusterGroup
                  removeOutsideVisibleBounds={true}
                  disableClusteringAtZoom={removePointsCluster ? 1 : undefined}
                >
                  {points &&
                    points.length > 0 &&
                    points.map(
                      ({
                        id,
                        address,
                        lastInstallDate,
                        lastReadDate,
                        latitude,
                        longitude,
                        number,
                        icon,
                      }) => {
                        return (
                          <Marker
                            key={id}
                            position={[latitude, longitude]}
                            icon={icon}
                          >
                            <TrapPopup
                              address={address}
                              lastInstallDate={lastInstallDate}
                              id={id}
                              lastReadDate={lastReadDate}
                              number={number}
                              setSelectedTrapNumber={setSelectedTrapNumber}
                              toggleEditTrapModal={toggleEditTrapModal}
                              latitude={latitude}
                              longitude={longitude}
                            />
                          </Marker>
                        );
                      }
                    )}
                </MarkerClusterGroup>
              </Overlay>
            );
          })
        : groupPointsList && groupPointsList.length > 0
        ? groupPointsList.map(({ name, points }) => {
            return (
              <Overlay
                key={`${name}-overlay-removePointsCluster-${removePointsCluster}`}
                name={name}
                checked={name === defaultSelectedPointsGroupName}
              >
                {removePointsCluster ? (
                  <MarkerClusterGroup
                    key={`${moment()}-removePointsCluster-${removePointsCluster}`}
                    removeOutsideVisibleBounds={true}
                    disableClusteringAtZoom={
                      removePointsCluster ? 1 : undefined
                    }
                  >
                    {points &&
                      points.length > 0 &&
                      points.map(
                        ({
                          id,
                          address,
                          lastInstallDate,
                          lastReadDate,
                          latitude,
                          longitude,
                          number,
                          icon,
                        }) => {
                          return (
                            <Marker
                              key={id}
                              position={[latitude, longitude]}
                              icon={icon}
                            >
                              <TrapPopup
                                address={address}
                                lastInstallDate={lastInstallDate}
                                id={id}
                                lastReadDate={lastReadDate}
                                number={number}
                                setSelectedTrapNumber={setSelectedTrapNumber}
                                toggleEditTrapModal={toggleEditTrapModal}
                                latitude={latitude}
                                longitude={longitude}
                              />
                            </Marker>
                          );
                        }
                      )}
                  </MarkerClusterGroup>
                ) : (
                  <MarkerClusterGroup
                    key={`${moment()}-${removePointsCluster}`}
                    removeOutsideVisibleBounds={true}
                  >
                    {points &&
                      points.length > 0 &&
                      points.map(
                        ({
                          id,
                          address,
                          lastInstallDate,
                          lastReadDate,
                          latitude,
                          longitude,
                          number,
                          icon,
                        }) => {
                          return (
                            <Marker
                              key={id}
                              position={[latitude, longitude]}
                              icon={icon}
                            >
                              <TrapPopup
                                address={address}
                                lastInstallDate={lastInstallDate}
                                id={id}
                                lastReadDate={lastReadDate}
                                number={number}
                                setSelectedTrapNumber={setSelectedTrapNumber}
                                toggleEditTrapModal={toggleEditTrapModal}
                                latitude={latitude}
                                longitude={longitude}
                              />
                            </Marker>
                          );
                        }
                      )}
                  </MarkerClusterGroup>
                )}
              </Overlay>
            );
          })
        : null}
    </LayersControl>
  );
};

const FilterMapTrapTools = ({ trapTypesList }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [filters, setFilters] = React.useState({
    trapNumber: "",
  });
  const { fetchTrapsListData } = useMapPoints();

  const handleFiltersChange = (filterName, newValue) => {
    setFilters((previousValue) => {
      return {
        ...previousValue,
        [filterName]: newValue,
      };
    });
  };

  return (
    <Control
      position="topright"
      className="leaflet-control-layers leaflet-control"
    >
      <Button onClick={() => setIsOpen((previousValue) => !previousValue)}>
        <i className="fa fa-search fa-2x"></i>
      </Button>

      {isOpen && (
        <section style={{ padding: "10px" }}>
          <div className="mb-3">
            <div style={{ display: "flex", alignItems: "end", gap: ".3rem", marginBottom: "1rem" }}>
              <div>
                <label htmlFor="trapNumber" className="h4">
                  Número da armadilha
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="trapNumber"
                  style={{ height: 0, padding: "0.8rem 0.3rem" }}
                  value={filters.trapNumber}
                  onChange={({ target: { value } }) =>
                    handleFiltersChange("trapNumber", value)
                  }
                />
              </div>
              <Button
                color="primary"
                style={{ width: "35px", padding: "2px" }}
                onClick={() => fetchTrapsListData(filters, trapTypesList)}
              >
                <i className="fa fa-filter"></i>
              </Button>
            </div>
            <div>
              <Button
                style={{ padding: ".5rem", backgroundColor: "#3b6bca", color: "white", border: "0px" }}
                onClick={() =>
                  fetchTrapsListData(
                    {
                      trapNumber: "",
                    },
                    trapTypesList
                  )
                }
              >
                Remover filtros
              </Button>
            </div>
          </div>
        </section>
      )}
    </Control>
  );
};

const RangeMapTrapTools = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [organizationId] = React.useState(getUserData("organizationId"));
  const { appliedFilters } = useAppliedFilters();
  const { removePointsCluster, setRemovePointsCluster } = useMapPoints();
  const {
    hadleTrapTypesRangesRadiusChange,
    trapTypesRangesRadius,
    setTrapsListToShowRange,
  } = useMapOperatingRanges();

  React.useEffect(() => {
    if (appliedFilters.territorializations.length !== 1) {
      setRemovePointsCluster(false);
    }
  }, [appliedFilters]);

  const DISABLED_ORGANIZATIONS_FROM_REMOVE_CLUSTERS = [
    "559a16ab-5da8-41ba-96b8-6e9f55feddd8",
    "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30",
    "7479f4e9-1706-41d4-8402-0bae72ef71ec",
  ];

  const isPointsClusterCheckboxDisabled =
    DISABLED_ORGANIZATIONS_FROM_REMOVE_CLUSTERS.includes(organizationId) &&
    appliedFilters.territorializations.length !== 1
      ? true
      : false;

  return (
    <Control
      position="topright"
      className="leaflet-control-layers leaflet-control"
    >
      <Button onClick={() => setIsOpen((previousValue) => !previousValue)}>
        <i className="fa fa-cog fa-2x"></i>
      </Button>

      {isOpen && (
        <section style={{ padding: "10px" }}>
          <div
            className="mb-3"
            style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
          >
            <input
              type="checkbox"
              id="trapClusters"
              checked={removePointsCluster}
              onChange={() =>
                setRemovePointsCluster((previousValue) => !previousValue)
              }
              disabled={isPointsClusterCheckboxDisabled}
            />
            <label
              htmlFor="trapClusters"
              className="h4 mb-0"
              style={{ opacity: isPointsClusterCheckboxDisabled ? 0.5 : 1 }}
            >
              Desagrupar armadilhas
            </label>
          </div>
          <div>
            <label className="h4 mb-0">Raios de atuação</label>
            <ul style={{ listStyle: "none", padding: "0" }}>
              {Object.keys(trapTypesRangesRadius).map((trapTypeName) => {
                return (
                  <li style={{ padding: "0 1rem" }}>
                    <div>
                      <label className="h5" htmlFor={`${trapTypeName}Range`}>
                        {ARMADILHA_INFO[trapTypeName].apelido}:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {trapTypesRangesRadius[trapTypeName]} metros
                        </span>
                      </label>
                    </div>
                    <input
                      type="range"
                      name={`${trapTypeName}Range`}
                      id={`${trapTypeName}Range`}
                      style={{ width: "100%" }}
                      min={1}
                      max={1000}
                      value={trapTypesRangesRadius[trapTypeName]}
                      onChange={({ target: { value } }) =>
                        hadleTrapTypesRangesRadiusChange(trapTypeName, value)
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <Button color="primary" onClick={() => setTrapsListToShowRange([])}>
              Desabilitar raios
            </Button>
          </div>
        </section>
      )}
    </Control>
  );
};

const TrapPopup = ({
  id,
  number,
  address,
  lastInstallDate,
  lastReadDate,
  setSelectedTrapNumber,
  toggleEditTrapModal,
}) => {
  return (
    <Popup key={`popup-${id}`}>
      <span>
        <span style={{ fontWeight: "bold" }}>Armadilha Nº: </span>
        {number}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Endereço: </span>
        {address}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última instalação: </span>
        {moment(lastInstallDate).format("DD/MM/YYYY HH:mm")}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última manutenção: </span>
        {!lastReadDate || lastReadDate === ""
          ? "-"
          : moment(lastReadDate).format("DD/MM/YYYY HH:mm")}
      </span>
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <Button
          data-tip
          data-for="editTrapModal"
          color="primary"
          onClick={() => {
            setSelectedTrapNumber(number);
            toggleEditTrapModal();
          }}
          style={{ margin: "0px" }}
        >
          <i className="fa fa-pen"></i>
        </Button>
        <ReactTooltip effect="solid" type="info" id="editTrapModal">
          Editar
        </ReactTooltip>
      </div>
    </Popup>
  );
};

const TrapsOperatingRangeWrapper = () => {
  const { trapsListToShowRange, trapTypesRangesRadius } =
    useMapOperatingRanges();

  return (
    <>
      {trapsListToShowRange.map(
        ({
          id,
          trapTypeName,
          fillColor,
          borderColor,
          latitude,
          longitude,
          number,
        }) => {
          return (
            <Circle
              key={id}
              center={[latitude, longitude]}
              color={borderColor}
              fillColor={fillColor}
              radius={
                trapTypesRangesRadius[trapTypeName]
                  ? trapTypesRangesRadius[trapTypeName]
                  : 12
              }
            >
              {/* {number ? <Tooltip sticky>{number}</Tooltip> : number} */}
              <Popup>
                <span style={{ display: "block" }}>
                  <span style={{ fontWeight: "bold" }}>Número: </span>
                  {number}
                </span>
                <span style={{ display: "block" }}>
                  <span style={{ fontWeight: "bold" }}>Tipo: </span>
                  {ARMADILHA_INFO[trapTypeName].apelido}
                </span>
              </Popup>
            </Circle>
          );
        }
      )}
    </>
  );
};

const FloatingPolygonPopup = ({ trapTypesList }) => {
  const [accordionsData, setAccordionsData] = React.useState([]);
  const [formatedPolygonData, setFormatedPolygonData] = React.useState(null);
  const { setTrapsListToShowRange } = useMapOperatingRanges();
  const { appliedFilters } = useAppliedFilters();

  const isPolygonPopupOpen = useContextSelector(
    MapContext,
    (context) => context.isPolygonPopupOpen
  );
  const togglePolygonPopup = useContextSelector(
    MapContext,
    (context) => context.togglePolygonPopup
  );
  const polygonPopupData = useContextSelector(
    MapContext,
    (context) => context.polygonPopupData
  );

  React.useEffect(() => {
    if (!isPolygonPopupOpen) setFormatedPolygonData(null);
  }, [isPolygonPopupOpen]);

  React.useEffect(() => {
    if (!polygonPopupData) return;

    calculatePolygonInformations(polygonPopupData);
  }, [polygonPopupData]);

  const calculatePolygonInformations = async (polygon) => {
    if (!polygon) return;

    const {
      territorialization: { id },
    } = polygon;

    const trapsList = await fetchTrapsListData(id);

    const { name } = polygon;
    const polygonAreaInSquareMeters = area(polygon);
    const polygonAreaInSquareKilometers = polygonAreaInSquareMeters / 1000000;
    const polygonAreaInHectare = polygonAreaInSquareMeters / 10000;

    const trapsListByTrapTypeMap = {};

    for (let i = 0; i < trapsList.length; i++) {
      const {
        id,
        number,
        trapType,
        colorStatus,
        lastInstallHistoryByStatusDate,
      } = trapsList[i];

      if (!trapsListByTrapTypeMap[trapType.name]) {
        trapsListByTrapTypeMap[trapType.name] = {
          activeTrapsList: [],
          greenTrapsList: [],
          yellowTrapsList: [],
          redTrapsList: [],
        };
      }

      const reducedTrapData = {
        id,
        colorStatus,
        fillColor: trapBackgroundColorByStatusNumber[colorStatus],
        borderColor: trapBackgroundColorByStatusNumber[colorStatus],
        number,
        trapTypeName: trapType.name,
        latitude: lastInstallHistoryByStatusDate.latitude,
        longitude: lastInstallHistoryByStatusDate.longitude,
      };

      trapsListByTrapTypeMap[trapType.name].activeTrapsList.push(
        reducedTrapData
      );

      if (colorStatus === GREEN_TRAP_STATUS) {
        trapsListByTrapTypeMap[trapType.name].greenTrapsList.push(
          reducedTrapData
        );

        continue;
      }

      if (colorStatus === YELLOW_TRAP_STATUS) {
        trapsListByTrapTypeMap[trapType.name].yellowTrapsList.push(
          reducedTrapData
        );
        continue;
      }

      if (colorStatus === RED_TRAP_STATUS) {
        trapsListByTrapTypeMap[trapType.name].redTrapsList.push(
          reducedTrapData
        );
        continue;
      }
    }

    const trapsListByTrapTypeResult = Object.entries(
      trapsListByTrapTypeMap
    ).map(([trapTypeName, traps]) => {
      const { activeTrapsList, greenTrapsList, yellowTrapsList, redTrapsList } =
        traps;

      const activeTrapsNumber =
        greenTrapsList.length + yellowTrapsList.length + redTrapsList.length;

      const trapsByAreaDensityInSquareKilometers = (
        activeTrapsNumber / polygonAreaInSquareKilometers
      ).toFixed(2);

      const trapsByAreaDensityInHectare = (
        activeTrapsNumber / polygonAreaInHectare
      ).toFixed(2);

      const areaByTrapsDensityInSquareKilometers = (
        polygonAreaInSquareKilometers / activeTrapsNumber
      ).toFixed(2);

      const areaByTrapsDensityInHectare = (
        polygonAreaInHectare / activeTrapsNumber
      ).toFixed(2);

      const greenTrapsPercentage = (
        (greenTrapsList.length * 100) /
        activeTrapsNumber
      ).toFixed(2);

      const yellowTrapsPercentage = (
        (yellowTrapsList.length * 100) /
        activeTrapsNumber
      ).toFixed(2);

      const redTrapsPercentage = (
        (redTrapsList.length * 100) /
        activeTrapsNumber
      ).toFixed(2);

      return {
        activeTrapsList,
        activeTrapsNumber,
        greenTrapsList,
        greenTrapsPercentage,
        yellowTrapsList,
        yellowTrapsPercentage,
        redTrapsList,
        redTrapsPercentage,
        trapTypeName,
        trapsByAreaDensityInSquareKilometers,
        trapsByAreaDensityInHectare,
        areaByTrapsDensityInSquareKilometers,
        areaByTrapsDensityInHectare,
        isOpen: false,
        ...traps,
      };
    });

    const formatedPolygon = {
      name,
      areaInSquareKilometers: polygonAreaInSquareKilometers.toFixed(2),
      areaInHectare: (polygonAreaInSquareMeters / 10000).toFixed(2),
      trapsListByTrapType: trapsListByTrapTypeResult,
    };

    setAccordionsData(trapsListByTrapTypeResult);
    setFormatedPolygonData(formatedPolygon);
  };

  const fetchTrapsListData = async (territorializationId) => {
    const trapsRangesToSend = trapTypesList.map(({ trapTypeName, value }) => {
      const { quantityRange, data } = getTrapRange(trapTypeName);

      return {
        trapTypeId: value,
        quantity: quantityRange,
        begin: data.beggining,
        middle: !data.middle ? null : data.middle,
        end: data.end,
      };
    });

    const filtersToSend = {
      colorStatuses: appliedFilters.colorStatuses.map(({ value }) => value),
      demandsIds: [],
      finalDate: getPeriodsToSendOnRequest(appliedFilters).finalDate,
      organizationId: getUserData("organizationId"),
      ranges: trapsRangesToSend,
      statuses: [ACTIVE_TRAP_STATUS],
      territorializationsIds: [territorializationId],
      trapsNumbers: [],
      trapTypesIds: [],
      usersIds: getUsersIdsToSendOnRequest(appliedFilters),
    };

    try {
      const { data, status } = await fetchFilteredTraps(filtersToSend);

      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelas armadilhas. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      return data;
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error.message);
      }
    }
  };

  const handleAccordionChange = (accordionIndex, propertyName, newValue) => {
    setAccordionsData((previousValues) => {
      const newValues = [...previousValues];

      newValues[accordionIndex] = {
        ...newValues[accordionIndex],
        [propertyName]: newValue,
      };

      return newValues;
    });
  };

  const styles = {
    draggableContainer: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "300px",
      padding: "20px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      zIndex: 999999,
    },
    polygonContentContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: ".5rem",
    },
    accordionContent: {
      padding: ".5rem 1.5rem",
      borderBottom: "1px solid #f0f1f3",
    },
    badge: {
      width: "20px",
      marginLeft: "5px",
    },
    trapsRangesButtons: {
      margin: 0,
      padding: "10px",
      border: 0,
    },
  };

  if (!isPolygonPopupOpen) return <></>;

  return (
    <Draggable>
      <div style={styles.draggableContainer}>
        {isPolygonPopupOpen && !formatedPolygonData ? (
          <div style={styles.polygonContentContainer}>
            <span>Carregando dados...</span>
            <LoadingSpin
              primaryColor="#3B6BCA"
              secondaryColor="#fff"
              width={5}
              size={15}
            />
          </div>
        ) : (
          <div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <i
                className="fa fa-times"
                onClick={() => togglePolygonPopup(null)}
                style={{ cursor: "pointer" }}
              ></i>
            </div>
            <span style={{ display: "block" }}>
              <span className="h4">Nome do polígono:</span>{" "}
              {formatedPolygonData.name}
            </span>
            <span style={{ display: "block" }}>
              <span className="h4">Área:</span>
            </span>
            <ul>
              <li>
                <span>{formatedPolygonData.areaInSquareKilometers} km²</span>
              </li>
              <li>
                <span>{formatedPolygonData.areaInHectare} ha</span>
              </li>
            </ul>
            <Accordion>
              {accordionsData &&
                accordionsData.length > 0 &&
                accordionsData.map(
                  (
                    {
                      activeTrapsList,
                      activeTrapsNumber,
                      greenTrapsPercentage,
                      yellowTrapsPercentage,
                      redTrapsPercentage,
                      greenTrapsList,
                      yellowTrapsList,
                      redTrapsList,
                      trapTypeName,
                      trapsByAreaDensityInSquareKilometers,
                      trapsByAreaDensityInHectare,
                      isOpen,
                    },
                    index
                  ) => {
                    const trapRange = getTrapRange(trapTypeName);
                    const trapTypeNickname =
                      ARMADILHA_INFO[trapTypeName].apelido;

                    const trapIcon =
                      ARMADILHA_INFO[trapTypeName].principal_icon;

                    return (
                      <AccordionItem style={{ padding: "0px" }} key={index}>
                        <AccordionHeader
                          isOpen={isOpen}
                          onClick={() =>
                            handleAccordionChange(index, "isOpen", !isOpen)
                          }
                          style={{ padding: ".8rem" }}
                        >
                          <div>
                            <img
                              src={trapIcon}
                              alt="trapIcon"
                              style={{ width: "25px" }}
                            />
                            <span className="h4" style={{ marginBottom: 0 }}>
                              {trapTypeNickname}
                            </span>
                          </div>
                        </AccordionHeader>
                        <AccordionContent
                          isOpen={isOpen}
                          style={styles.accordionContent}
                        >
                          <section style={{ fontSize: ".8rem" }}>
                            <div>
                              <span style={{ display: "block" }}>
                                <span
                                  className="h4"
                                  style={{ fontSize: ".8rem" }}
                                >
                                  Armadilhas:
                                </span>
                              </span>
                              <ul style={{ paddingLeft: "1.5rem" }}>
                                <li>
                                  <span>
                                    Total instaladas
                                    <Badge
                                      style={{
                                        ...styles.badge,
                                        backgroundColor: "#3b6bca",
                                      }}
                                    >
                                      &nbsp;
                                    </Badge>{" "}
                                    : {activeTrapsNumber}
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Verdes
                                    <Badge
                                      style={{
                                        ...styles.badge,
                                        backgroundColor: "#76ea79",
                                      }}
                                    >
                                      &nbsp;
                                    </Badge>{" "}
                                    : {greenTrapsList.length} /{" "}
                                    {greenTrapsPercentage}%
                                  </span>
                                </li>
                                {trapRange.quantityRange === 3 && (
                                  <li>
                                    <span>
                                      Amarelas
                                      <Badge
                                        style={{
                                          ...styles.badge,
                                          backgroundColor: "#fff200",
                                        }}
                                      >
                                        &nbsp;
                                      </Badge>{" "}
                                      : {yellowTrapsList.length} /{" "}
                                      {yellowTrapsPercentage}%
                                    </span>
                                  </li>
                                )}
                                <li>
                                  <span>
                                    Vermelhas
                                    <Badge
                                      style={{
                                        ...styles.badge,
                                        backgroundColor: "#ff0000",
                                      }}
                                    >
                                      &nbsp;
                                    </Badge>{" "}
                                    : {redTrapsList.length} /{" "}
                                    {redTrapsPercentage}%
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <span style={{ display: "block" }}>
                                <span
                                  className="h4"
                                  style={{ fontSize: ".8rem" }}
                                >
                                  Densidade{" "}
                                </span>
                                (armadilhas / área):{" "}
                              </span>
                              <ul style={{ paddingLeft: "1.5rem" }}>
                                <li>
                                  <span>
                                    {trapsByAreaDensityInSquareKilometers}{" "}
                                    {trapTypeNickname} / km²
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    {trapsByAreaDensityInHectare}{" "}
                                    {trapTypeNickname} / ha
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                justifyContent: "center",
                                marginBottom: ".8rem",
                              }}
                            >
                              <div>
                                <Button
                                  data-tip
                                  data-for={`${trapTypeName}-installedTraps`}
                                  style={{
                                    backgroundColor: "#3b6bca",
                                    ...styles.trapsRangesButtons,
                                  }}
                                  onClick={() =>
                                    setTrapsListToShowRange(activeTrapsList)
                                  }
                                >
                                  <img
                                    src={trapIcon}
                                    style={{ width: "25px" }}
                                    alt="iconTrap"
                                  />
                                </Button>
                                <ReactTooltip
                                  effect="solid"
                                  type="info"
                                  id={`${trapTypeName}-installedTraps`}
                                >
                                  Habilitar raios de todas as {trapTypeNickname}
                                  's
                                </ReactTooltip>
                              </div>
                              <div>
                                <Button
                                  data-tip
                                  data-for={`${trapTypeName}-greenTraps`}
                                  style={{
                                    backgroundColor: "#76ea79",
                                    ...styles.trapsRangesButtons,
                                  }}
                                  onClick={() =>
                                    setTrapsListToShowRange(greenTrapsList)
                                  }
                                >
                                  <img
                                    src={trapIcon}
                                    style={{ width: "25px" }}
                                    alt="iconTrap"
                                  />
                                </Button>
                                <ReactTooltip
                                  effect="solid"
                                  type="info"
                                  id={`${trapTypeName}-greenTraps`}
                                >
                                  Habilitar raios das {trapTypeNickname}'s
                                  verdes
                                </ReactTooltip>
                              </div>
                              {trapRange.quantityRange === 3 && (
                                <div>
                                  <Button
                                    data-tip
                                    data-for={`${trapTypeName}-yellowTraps`}
                                    style={{
                                      backgroundColor: "#fff200",
                                      ...styles.trapsRangesButtons,
                                    }}
                                    onClick={() =>
                                      setTrapsListToShowRange(yellowTrapsList)
                                    }
                                  >
                                    <img
                                      src={trapIcon}
                                      style={{ width: "25px" }}
                                      alt="iconTrap"
                                    />
                                  </Button>
                                  <ReactTooltip
                                    effect="solid"
                                    type="info"
                                    id={`${trapTypeName}-yellowTraps`}
                                  >
                                    Habilitar raios das {trapTypeNickname}'s
                                    amarelas
                                  </ReactTooltip>
                                </div>
                              )}
                              <div>
                                <Button
                                  data-tip
                                  data-for={`${trapTypeName}-redTraps`}
                                  style={{
                                    backgroundColor: "#ff0000",
                                    ...styles.trapsRangesButtons,
                                  }}
                                  onClick={() =>
                                    setTrapsListToShowRange(redTrapsList)
                                  }
                                >
                                  <img
                                    src={trapIcon}
                                    style={{ width: "25px" }}
                                    alt="iconTrap"
                                  />
                                </Button>
                                <ReactTooltip
                                  effect="solid"
                                  type="info"
                                  id={`${trapTypeName}-redTraps`}
                                >
                                  Habilitar raios das {trapTypeNickname}'s
                                  vermelhas
                                </ReactTooltip>
                              </div>
                            </div>
                            <div>
                              <Button
                                color="primary"
                                onClick={() => setTrapsListToShowRange([])}
                              >
                                Desabilitar raios
                              </Button>
                            </div>
                          </section>
                        </AccordionContent>
                      </AccordionItem>
                    );
                  }
                )}
            </Accordion>
          </div>
        )}
      </div>
    </Draggable>
  );
};

export default React.memo(TrapsPageMapWrapperComponent);
