import React from "react";
// react component that copies the given text inside your clipboard
import { Link } from "react-router-dom";
// reactstrap components
import ReactTooltip from 'react-tooltip';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import "../../../assets/css/style.css";
import { changeSample, fetchSamplesByNumber } from "../../../services/api/Sample";
import {getConservationTypes} from "../../../services/api/ConservationType";
import {getJustificationDisposalTypes} from "../../../services/api/JustificationDispositionType";
import { fetchPrograms } from "../../../services/api/Program";
import {getDiagnosticsExam,getIndentificationsExam } from "../../../services/api/Exam";
import {getTechniques} from "../../../services/api/Technique";
import { fetchIdentifications } from "../../../services/api/Identification";
import { getTrapById,getTrapsTypes } from "../../../services/api/Trap";
import { fetchDemand } from "../../../services/api/Demand";
import { toast, ToastContainer } from "react-toastify";
import { fetchAnimalTypeGroups, fetchAnimalGenres, fetchAnimalGroups, fetchAnimalSpecies, fetchGenreVirusType } from "../../../services/api/AnimalGroups";
import Select from "react-select";
import {getAllLaboratories} from "../../../services/api/Laboratory";
import { getUser } from "../../../services/api/Users";
import { getPerson } from "../../../services/api/Person";
import { checkPermissionComponent } from "../../../services/utils/permission";
import { getUserData } from '../../../services/utils/auth';
import {ARMADILHA_INFO} from '../../../constants/ArmadilhaConstant';
import "./Sample.css";
import { timeStampFormated } from "../../../services/utils/format";
const groupedOptions = [
  {
    label: "Invertebrados",
    options: [
      { name: "Stage", label: "Ovo", value: "Ovo" },
      { name: "Stage", label: "Larva", value: "Larva" },
      { name: "Stage", label: "Ninfa", value: "Ninfa" },
      { name: "Stage", label: "Pupa", value: "Pupa" },
      { name: "Stage", label: "Adulto", value: "Mosquito Adulto" },
    ]
  },
  { label: "Vertebrados", 
    options: [
      { name: "Stage", label: "Filhote", value: "Filhote" },
      { name: "Stage", label: "Jovem", value: "Jovem" },
      { name: "Stage", label: "Adulto", value: "Adulto" }
    ] 
  }
];
const groupedInvertebratesOptions = [
  {
    label: "Invertebrados",
    options: [
      { name: "Stage", label: "Ovo", value: "Ovo" },
      { name: "Stage", label: "Larva", value: "Larva" },
      { name: "Stage", label: "Pupa", value: "Pupa" },
      { name: "Stage", label: "Adulto", value: "Mosquito Adulto" },
    ]
  }
];
const options =[
  { name: "Stage", label: "Ovo", value: "Ovo" },
  { name: "Stage", label: "Larva", value: "Larva" },
  { name: "Stage", label: "Ninfa", value: "Ninfa" },
  { name: "Stage", label: "Pupa", value: "Pupa" },
  { name: "Stage", label: "Adulto", value: "Mosquito Adulto" },
  { name: "Stage", label: "Filhote", value: "Filhote" },
  { name: "Stage", label: "Jovem", value: "Jovem" },
  { name: "Stage", label: "Adulto", value: "Adulto" }
];



class AddSampleResult extends React.Component {

    constructor (props) {
      super(props);
      if(!checkPermissionComponent("7d306cb1-0df5-4fdc-a658-8f7492292172", "create")){
        alert("Você não tem permissão para acessar essa funcionalidade!");
        props.history.goBack(); 
      }
      this.state = {
        showResults: true,
        wasSaved: false,
        enableEditNumber: false,
        sampleData: "1",
        sampleNumber: "",
        sampleResults: [],
        sampleConservationType:"00000000-0000-0000-0000-000000000000",
        sampleJustificationDisposalTypes:[],
        techniques:[],
        sampleClassification: [{
          sampleClassificationId:"00000000-0000-0000-0000-000000000000",
          LaboratoryId:"00000000-0000-0000-0000-000000000000",
          externSampleNumber:"",
          description:"",
          exams:[{
            examsHistoryId: "00000000-0000-0000-0000-000000000000",
            examId:"00000000-0000-0000-0000-000000000000",
            techniques:[],
            techniqueId:"00000000-0000-0000-0000-000000000000",
          }],
        }],
        sampleDiagnosis:[{
          SampleResultId:"00000000-0000-0000-0000-000000000000",
          LaboratoryId:"00000000-0000-0000-0000-000000000000",
          externSampleNumber:"",
          description:"",
          exams:[{
            examDiagnosticId: "00000000-0000-0000-0000-000000000000",
            examId:"00000000-0000-0000-0000-000000000000",
            techniques:[],
            techniqueId:"00000000-0000-0000-0000-000000000000",
          }],
          diagnostic:"00000000-0000-0000-0000-000000000000",
          result:null,
        }],
        sampleIdentifications:[{
          SampleIdentificationId:"00000000-0000-0000-0000-000000000000",
          Gender:"",
          Stage:"",
          Quantity:0,
          QuantityAlive:0,
          QuantityDead:0,
          Show: true
        }],
        animalTypeGroups:[],
        animalGroups: [],
        animalGenres:[],
        animalSpecies:[],
        selectedAnimalTypeGroup: '',
        selectedAnimalGroup:'00000000-0000-0000-0000-000000000000',
        selectedAnimalGenre:'00000000-0000-0000-0000-000000000000',
        selectedAnimalSpecie:'00000000-0000-0000-0000-000000000000',
        selectedVirusType: '00000000-0000-0000-0000-000000000000',
        LaboratoryId:'',
        programs: [],
        selectedProgram: "",
        exams:[],
        identificationsExams: [],
        diagnosticsExams: [],
        identifications: [],
        isPossibleDiagnosis:true,
        Demand:null,
        Trap:null,
        Laboratories:[],
        ConservationTypes:[],
        JustificationDisposalTypes:[],
        isDisableOrganization: false
      };
    }

  handleChange(event) {
    this.setState({[event.target.name]  : event.target.value });
  };

  loadSampleData = async () => {
    this.resetState(this.state.sampleNumber);
    var sampleNumber = this.state.sampleNumber;
    //requisicao para pegar os dados da amostra
    if(sampleNumber !== ""){
      var requestSample = fetchSamplesByNumber(sampleNumber);
      requestSample.then(async (response) => {
        if(response.data){
          let sample = response.data;
          if(sample.status !== "Discarded" && sample.status !== "Diagnostic not possible"){
            if(sample.typeAnimalGroupIdentified !== undefined){
              if(sample.typeAnimalGroupIdentified == "00000000-0000-0000-0000-000000000000") {
                await this.selectedTypeGroup(this.state.animalTypeGroups.filter(obj => obj.label == "Vetores")[0].value);
              } else {
                await this.selectedTypeGroup(sample.typeAnimalGroupIdentified);
              }

              if(sample.animalIdentified !== undefined){
                if(sample.animalIdentified == "00000000-0000-0000-0000-000000000000") {
                  await this.selectedGroup(this.state.animalGroups.filter(obj =>  obj.label == "Mosquitos")[0].value);
                } else {
                  await this.selectedGroup(sample.animalIdentified);
                }
              }

              if(sample.genderIdentified !== undefined){
                if(sample.genderIdentified == "00000000-0000-0000-0000-000000000000" && sample.typeMaterial == "Palhetas") {
                  await this.selectedGenre(this.state.animalGenres.filter(obj => obj.label == "Aedes")[0].value)
                } else {
                  await this.selectedGenre(sample.genderIdentified);
                }
              }

              if(sample.speciesIdentified !== undefined ){
                this.state.selectedAnimalSpecie = sample.speciesIdentified  
              }

              if(sample.virusTypeIdentified !== undefined){
                this.state.selectedVirusType = sample.virusTypeIdentified
              }
            }

            //pegando os registros de classificação.
            if(sample.identificationSampleHistory != null && sample.identificationSampleHistory.length > 0){
              sample.identificationSampleHistory.forEach((classification, index) =>{
                
                let examsObj = [];
                classification.exams.forEach((exam, index) => {
                  examsObj[index] = {
                    examsHistoryId: exam.examsHistoryId,
                    examId:exam.examId,
                    techniques:exam.techniques,
                    techniqueId:exam.techniqueId,
                  }
                })
                let classificationObj = {
                  sampleClassificationId:classification.sampleClassificationId,
                  LaboratoryId:classification.laboratoryId,
                  externSampleNumber:classification.externSampleNumber,
                  description: classification.description,
                  exams: examsObj,
                }
                this.state.sampleClassification[index] = classificationObj;
              })
            }

            //pegando os registros de diagnóstico
            if(sample.sampleDiagnosticHistory != null && sample.sampleDiagnosticHistory.length > 0){
              sample.sampleDiagnosticHistory.forEach((diagnostic, index) =>{
                
                let examsObj = [];
                diagnostic.exams.forEach((exam, index) => {
                  examsObj[index] = {
                    examDiagnosticId: exam.examDiagnosticId,
                    examId:exam.examId,
                    techniques:exam.techniques,
                    techniqueId:exam.techniqueId,
                  }
                })
                let diagnosticObj = {
                  SampleResultId:diagnostic.sampleResultId,
                  LaboratoryId:diagnostic.laboratoryId,
                  externSampleNumber:diagnostic.externSampleNumber,
                  exams:examsObj,
                  description: diagnostic.description,
                  diagnostic:diagnostic.diagnostic,
                  result:diagnostic.result,
                }
                this.state.sampleDiagnosis[index] = diagnosticObj;
              })
            }

            //pegando os registros de identificação

            if(sample.sampleAnalysisIdentification != null && sample.sampleAnalysisIdentification.length > 0){
              sample.sampleAnalysisIdentification.forEach((analysisIdentification, index) => {
                let analysisIdentificationObj = {
                  SampleAnalysisIdentificationId:analysisIdentification.sampleAnalysisIdentificationId,
                  Gender:analysisIdentification.gender,
                  Stage:analysisIdentification.stage,
                  Quantity:analysisIdentification.quantity,
                  QuantityAlive:analysisIdentification.quantityAlive,
                  QuantityDead:analysisIdentification.quantityDead,
                }

                this.state.sampleIdentifications[index] = analysisIdentificationObj;
              })
            } else {
              var stage = "";

              if(sample.typeMaterial == "Palhetas") {
                stage = "Ovo";
              } else if(sample.typeMaterial == "Mosquito Adulto") {
                stage = "Mosquito Adulto";
              }

              let analysisIdentificationObj = {
                SampleAnalysisIdentificationId: "00000000-0000-0000-0000-000000000000",
                Gender: "",
                Stage: stage,
                Quantity:0,
                QuantityAlive:0,
                QuantityDead:0,
              }


              this.state.sampleIdentifications[0] = analysisIdentificationObj;
            }

            //se entrar nesse if é pq possui algum resultado na amostra.
            if(sample.sampleAnalysisIdentification.length > 0 || sample.sampleDiagnosticHistory.length > 0 || sample.identificationSampleHistory.length > 0){
              this.state.showResults = true;
            }

            if(sample.conservationTypeId == "00000000-0000-0000-0000-000000000000") {
              this.state.ConservationTypes.map(type => {                
                if(type.name == "Vivo") {
                  this.setState({sampleConservationType: type.id})
                }
              })
            } else {
              this.state.sampleConservationType = sample.conservationTypeId;
            }

            console.log("sample", sample)

            this.setState({sampleData : sample});
          }else{
            toast.error("Erro: A amostra está inviável ou foi descartada!");
          }
          
        }

        var requestDemand = fetchDemand(this.state.sampleData.demandId);
        requestDemand.then(responseDemand =>{
          this.setState({Demand:responseDemand.data})
        })

        var trapTypes;
        var traptyperequest = getTrapsTypes();
        traptyperequest.then(typeRequest=>{
          trapTypes = typeRequest.data;
          var requestTrap = getTrapById(this.state.sampleData.trapId);
          requestTrap.then(responseTrap =>{
            trapTypes.map(type=>{
              if(type.id == responseTrap.data.trapTypeId){
                responseTrap.data.trapTypeId = ARMADILHA_INFO[type.name].apelido;
                this.setState({Trap:responseTrap.data});
              }
            })
          })
        })
        

        var requestUser = getUser(this.state.sampleData.collectedBy)
        requestUser.then(response => {
          if(response.data){
            let requestPerson = getPerson(response.data.personId);
            requestPerson.then(responsePerson => {
              if(responsePerson.data){
                this.state.sampleData.personName = responsePerson.data.name;
                this.forceUpdate();
              }
            })
          }
        })
      }).catch(e => {
        toast.error("Erro: Amostra inexistente");
      });
    }else{
      toast.error("Erro: É necessário digitar um número de amostra");
    }
  }

  async componentDidMount (){
    
    this.setState({isPossibleDiagnosis : true})
    var isDisableOrganization = getUserData("organizationId") == "37601ec4-d2ef-4f86-aeac-fd261e8fded4";
    this.setState({isDisableOrganization: isDisableOrganization});
    var requestLaboratories = getAllLaboratories();
    requestLaboratories.then(response =>{
      response.data.map(laboratory=>{
        this.state.Laboratories.push({
          target:{
            name:"LaboratoryId",
            value: laboratory.id,
          },
          label:laboratory.name,
          value:laboratory.id
        })
      });
    });
    
    var requestPrograms = fetchPrograms();
    requestPrograms.then(response => {
      this.setState({programs: response.data});
    });

    var requestIdExams = getIndentificationsExam();
    requestIdExams.then(response => {
      this.setState({identificationsExams: response.data});
    });
    var requestDiaExams = getDiagnosticsExam();
    requestDiaExams.then(response => {
      this.setState({diagnosticsExams: response.data});
    });
    var requestIdentifications = fetchIdentifications();
    requestIdentifications.then(response =>{
      this.setState({identifications: response.data})
    });
    var requestTechnique = getTechniques();
    requestTechnique.then(response => {
      this.setState({techniques: response.data});
    });

    var sampleNumber = this.state.sampleNumber;
    if(sampleNumber !== ""){
      var requestSample = fetchSamplesByNumber(sampleNumber);
      requestSample.then(response => {
      let sample = response.data;
      this.setState({sampleData : sample, sampleConservationType: sample.conservationTypeId});
    })}

    var requestConservationType = getConservationTypes();
    requestConservationType.then(response => {
      let types = [];
      
      response.data.map(type => {
        types.push({
          label:type.name,
          value:type.id,
        });
        
        if(type.name == "Vivo") {
          this.setState({sampleConservationType: type.id})
        }
      })

      this.setState({ConservationTypes : types});
    });

    var requestJustificationDisposalType = getJustificationDisposalTypes();
    requestJustificationDisposalType.then(response => {
      let types = [];
      
      response.data.map(type => {
        types.push({
          label:type.name,
          value:type.id,
        });
      })
        this.setState({JustificationDisposalTypes : types});
    });

    var requestAnimalTypeGroups = fetchAnimalTypeGroups();
    await requestAnimalTypeGroups.then(async response => {
      let typeGroups = [];
      await response.data.map(typeGroup => {
        typeGroups.push({
          label:typeGroup.name,
          value:typeGroup.id,
        });
      })
      this.setState({animalTypeGroups: typeGroups});
    })
    if(this.props.location.data!= null){
      this.setState({ sampleNumber: this.props.location.data.number }, () => {
        this.loadSampleData();
      });
    } 

    
    if(isDisableOrganization){
      await this.selectedTypeGroup("84f3cd9c-9894-4021-9355-2d1b2ed15e0d");
      await this.selectedGroup("178886c4-a41c-4d15-ac3c-a58806bb8df9");
      this.setState({selectedAnimalTypeGroup:"84f3cd9c-9894-4021-9355-2d1b2ed15e0d", selectedAnimalGroup:"178886c4-a41c-4d15-ac3c-a58806bb8df9"});
    }
  }

  handleChangeDiagnosis(index, element, array = null, arrayIndex = null){
    let sampleDiagnosis = this.state.sampleDiagnosis;
    if(array !== null){
      sampleDiagnosis[index][array][arrayIndex][element.target.name] = element.target.value;

      if(element.target.name == 'examId'){
        let examSelected = this.state.exams.filter((exam) => exam.id == element.target.value);
        if(examSelected.length > 0){
          sampleDiagnosis[index][array][arrayIndex].techniques = examSelected[0].techniques;
          sampleDiagnosis[index][array][arrayIndex].techniqueId = "00000000-0000-0000-0000-000000000000";
        }
      }
    }else{        
      sampleDiagnosis[index][element.target.name] = element.target.value;
    }

    this.setState({sampleDiagnosis:sampleDiagnosis});
  }

  handleFowardedSample(classification,index, array = null, arrayIndex = null){
    let sampleFowardClassification = this.state.sampleClassification;
    if(array !== null){
      sampleFowardClassification[index][array][arrayIndex][classification.target.name] = classification.target.value;

      if(classification.target.name == 'examId'){
        let examSelected = this.state.exams.filter((exam) => exam.id == classification.target.value);
        if(examSelected.length > 0){
          sampleFowardClassification[index][array][arrayIndex].techniques = examSelected[0].techniques;
          sampleFowardClassification[index][array][arrayIndex].techniqueId = "00000000-0000-0000-0000-000000000000";
        }
      }

    }else{
      sampleFowardClassification[index][classification.target.name] = classification.target.value;
    }
    this.setState({sampleClassification:sampleFowardClassification});
  }

  handleChangeIdentification(identification, index){
    let sampleIdentifications = this.state.sampleIdentifications;
    if(identification.target){
      if(identification.target.name == 'Quantity' || identification.target.name == 'QuantityAlive' || identification.target.name == 'QuantityDead'){
        let value = parseInt(identification.target.value);
        sampleIdentifications[index][identification.target.name] = value >= 0 || value < 0 ? value : 0; //prevenindo bug
        if(identification.target.name !== 'Quantity'){
          sampleIdentifications[index]["Quantity"] = sampleIdentifications[index]["QuantityAlive"] +  sampleIdentifications[index]["QuantityDead"];
        }
      }else{
        sampleIdentifications[index][identification.target.name] = identification.target.value;  
      }
    }else{
      sampleIdentifications[index][identification.name] = identification.value;
    }
    this.setState({sampleIdentifications: sampleIdentifications});
  }

  removeResult = (index) =>{
      var sampleResults = this.state.sampleResults;
      sampleResults.splice(index, 1);
      this.setState({sampleResults : sampleResults});
      
  }

  sendSampleResult = (saveAndContinue) =>{

    if(this.state.wasSaved == true)
    {
      toast.info("Resultado cadastrado anteriormente.");
      return;
    }

    if(this.state.sampleNumber == '')
    {
      toast.error("Erro: Número da amostra em branco");
      return;
    }

    let error = false;
    this.state.sampleIdentifications.forEach((identification) => {
      if (identification.Stage === "") {
        toast.error("Erro: Não foi inserido a Fase na identificação");
        error = true;
        return;
      }
    });

    if (error) {
      return;
    }

    if(this.state.isPossibleDiagnosis){
      this.state.sampleData.genderIdentified = this.state.selectedAnimalGenre;
      this.state.sampleData.virusTypeIdentified = this.state.selectedVirusType;
      this.state.sampleData.speciesIdentified = this.state.selectedAnimalSpecie;
      this.state.sampleData.typeAnimalGroupIdentified = this.state.selectedAnimalTypeGroup;
      this.state.sampleData.animalIdentified = this.state.selectedAnimalGroup;
      this.state.sampleData.identificationSampleHistory = this.state.sampleClassification;
      this.state.sampleData.sampleDiagnosticHistory = this.state.sampleDiagnosis;
      this.state.sampleData.sampleAnalysisIdentification = this.state.sampleIdentifications;
      this.state.sampleData.ConservationTypeId = this.state.sampleConservationType;
      this.state.sampleData.Number = this.state.sampleNumber;
      this.state.sampleData.status = "Analyzed";

      let requestChangeSample = changeSample(this.state.sampleData);
      requestChangeSample.then((response) => {
        if(response.data.id) {
          toast.success("Resultado cadastrado com sucesso!");
          this.setState({wasSaved: true});
          if(saveAndContinue == true) {
            if(this.state.sampleData.visitId != "" || this.state.sampleData.visitId != null)
            localStorage.setItem("visitId", this.state.sampleData.visitId);                
          }
        }
      })
    }else{
      this.state.sampleData.status = "Diagnostic not possible";
      this.state.sampleData.ListJustificationsDisposalTypes = this.state.sampleJustificationDisposalTypes;
      let requestChangeSample = changeSample(this.state.sampleData);
      requestChangeSample.then((response) => {
        if(response.data.id) {
          toast.success("Resultado cadastrado com sucesso!");
          
        }
      })
    }      
  }

  selectedTypeGroup = async (typeGroup) =>{
    const {data} = await fetchAnimalGroups(typeGroup);
    const groups = data.map(({name, id}) => ({label: name, value: id}))
    
    this.setState({
      selectedAnimalTypeGroup:typeGroup,
      selectedAnimalGroup: '00000000-0000-0000-0000-000000000000',
      selectedAnimalGenre: '00000000-0000-0000-0000-000000000000',
      selectedVirusType: '00000000-0000-0000-0000-000000000000',
      selectedAnimalSpecie: '00000000-0000-0000-0000-000000000000',
      animalGroups: groups,
    });
    
    
  }

  selectedGroup = async (group) =>{
    const {data} = await fetchAnimalGenres(group);
    const genres = data.map(({name, id}) => ({ label: name, value: id }))

    this.setState({
      selectedAnimalGroup: group,
      selectedAnimalGenre: '00000000-0000-0000-0000-000000000000',
      selectedVirusType: '00000000-0000-0000-0000-000000000000',
      selectedAnimalSpecie: '00000000-0000-0000-0000-000000000000',
      animalGenres: genres,
    });
  }

  selectedGenre = async (genre) =>{
    try{
      const specieResponse = await fetchAnimalSpecies(genre);
      const species = specieResponse.data.map(({name, id}) => ({label: name, value: id}));
  
      const virusResponse = await fetchGenreVirusType(genre);
      const viruses = virusResponse.data.map(({name, id}) => ({label: name, value: id}));
      this.setState({
        selectedAnimalGenre: genre,
        selectedAnimalSpecie: '00000000-0000-0000-0000-000000000000',
        selectedVirusType: '00000000-0000-0000-0000-000000000000',
        animalSpecies: species,
        virusTypes: viruses
      });
    }catch(error){

    }
  }

  resetState(sampleNumber){
    this.state.sampleData = "1";
    this.state.sampleNumber = sampleNumber;
    this.state.sampleResults = [];
    this.state.animalGroups = [];
    this.state.animalGenres = [];
    this.state.animalSpecies = [];
    this.state.selectedAnimalTypeGroup = '';
    this.state.selectedAnimalGroup = '00000000-0000-0000-0000-000000000000';
    this.state.selectedAnimalGenre = '00000000-0000-0000-0000-000000000000';
    this.state.selectedAnimalSpecie ='00000000-0000-0000-0000-000000000000';
    this.state.selectedVirusType = '00000000-0000-0000-0000-000000000000';
    this.state.programs = [];
    this.state.selectedProgram = "";
    this.state.identifications = [];
    this.state.isPossibleDiagnosis = true;
    this.state.Demand = null;
    this.state.Trap = null;

    this.forceUpdate();
  }

  addDiagnosis(){
    let diagnosis = this.state.sampleDiagnosis;
    diagnosis.push({
      SampleResultId:"00000000-0000-0000-0000-000000000000",
      ExternLaboratoryId:"00000000-0000-0000-0000-000000000000",
      externSampleNumber:"",
      description:"",
      exams:[{
        examDiagnosticId: "00000000-0000-0000-0000-000000000000",
        examId:"00000000-0000-0000-0000-000000000000",
        techniques:[],
        techniqueId:"00000000-0000-0000-0000-000000000000",
      }],
      diagnostic:"",
      result:false,
    })

    this.setState({sampleDiagnosis: diagnosis});
  }

  removeDiagnosis = () =>{
    var sampleDiagnosis = this.state.sampleDiagnosis;
    sampleDiagnosis.pop();
    this.setState({sampleDiagnosis : sampleDiagnosis});
    
  }

  addDiagnosisExam(index){
    let sampleDiagnosis = this.state.sampleDiagnosis;
    sampleDiagnosis[index].exams.push({
      examDiagnosticId: "00000000-0000-0000-0000-000000000000",
      examId:"00000000-0000-0000-0000-000000000000",
      techniques:[],
      techniqueId:"00000000-0000-0000-0000-000000000000",
    })
    this.setState({sampleDiagnosis : sampleDiagnosis});
  }

  removeDiagnosisExam(index){
    let sampleDiagnosis = this.state.sampleDiagnosis;
    sampleDiagnosis[index].exams.pop();
    this.setState({sampleDiagnosis : sampleDiagnosis})
  }

  addLabForward(){
    let classifications = this.state.sampleClassification;
    classifications.push({
      sampleClassificationId:"00000000-0000-0000-0000-000000000000",
      LaboratoryId:"00000000-0000-0000-0000-000000000000",
      externSampleNumber:"",
      description:"",
      exams:[{
        identificationSampleHistoryId: "00000000-0000-0000-0000-000000000000",
        examId:"00000000-0000-0000-0000-000000000000",
        techniques:[],
        techniqueId:"00000000-0000-0000-0000-000000000000",
      }],
    })

    this.setState({sampleClassification: classifications});
  }

  removeLabForward = () =>{
    var sampleClassification = this.state.sampleClassification;
    sampleClassification.pop();
    this.setState({sampleClassification : sampleClassification});
    
  }

  addClassificationExam(index){
    let sampleClassification = this.state.sampleClassification;
    sampleClassification[index].exams.push({
      identificationSampleHistoryId: "00000000-0000-0000-0000-000000000000",
      examId:"00000000-0000-0000-0000-000000000000",
      techniques:[],
      techniqueId:"00000000-0000-0000-0000-000000000000",
    })
    this.setState({sampleClassification : sampleClassification});
  }

  removeClassificationExam(index){
    let sampleClassification = this.state.sampleClassification ;
    sampleClassification[index].exams.pop();
    this.setState({sampleClassification : sampleClassification})
  }

  addIdentification(){
    let sampleIdentifications = this.state.sampleIdentifications;
    sampleIdentifications.push({
      SampleIdentificationId:"00000000-0000-0000-0000-000000000000",
      Gender:"",
      Stage:"",
      Quantity:0,
      QuantityAlive:0,
      QuantityDead:0,
      Show: true
    });
    this.setState({sampleIdentifications: sampleIdentifications});
  }

  removeIdentification(indexIdentification){
    let sampleIdentifications = this.state.sampleIdentifications;
    sampleIdentifications[indexIdentification].Quantity = 0;
    sampleIdentifications[indexIdentification].Show = false;
    this.setState({sampleIdentifications:sampleIdentifications});
  }

  addJustificationType(e){
    var justifications = [];
    if(e !== null){
      e.map(justificationType=>{
        justifications.push({"id":justificationType.value,"name":justificationType.label,"description":""});
      })
    }
    
    this.setState({sampleJustificationDisposalTypes:justifications});
  }

  render() {
    var {sampleData} = this.state;
    var {sampleResults} = this.state;
    var QuantitySoma =0, QuantityAliveSoma =0, QuantityDeadSoma=0;
    this.state.sampleIdentifications.forEach((identification)=>{
      QuantitySoma +=identification.Quantity;
      QuantityAliveSoma += identification.QuantityAlive;
      QuantityDeadSoma += identification.QuantityDead;
    })
    
    

    var showResult;
    //this.state.sampleNumber = this.props.sample!=null?this.props.sample.number:''
    return (
      <>
      <ToastContainer/>
        <Container className="container-flex-result" fluid>
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <Link to="/admin/amostrasColetadas">
                    <h3 className="mb-0">
                      <i className=" ni ni-bold-left bnt-voltar" />
                      Voltar
                    </h3>
                  </Link>
                </div>
              </Row>
            </CardHeader>

            <Container fluid>
              <div className="row linha-form">
                <div className="col-4 responsive-form-field-result-medium">
                  <span className="h5 text-black">Número da Amostra</span>
                  <input value={this.state.sampleNumber}  type="text" className="form-control" placeholder="Número da amostra" name="sampleNumber" onChange={ (e) => this.handleChange(e) } />
                </div>
                <div className="col-4 responsive-form-field-result-medium">
                <span className="h5 text-black">&nbsp;</span>
                  <button onClick={() => this.setState({wasSaved: false}, this.loadSampleData)} className="btn btn-primary responsive-button-result">Verificar</button>
                </div>
              </div>
              
              {this.state.sampleData !== '1'?<Card style={{"marginTop": "2%", "marginBottom": "2%"}}> 
                  <CardHeader style={{"border": "0.1px solid lightgrey"}}>
                    <span className="h5 text-black">Detalhes da amostra</span>
                  </CardHeader>
                  <CardBody style={{"border": "0.1px solid lightgrey"}}>
                  <div className="row"style={{"marginTop": "2%", "marginBottom": "2%"}} >
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">N. da Amostra: </span>
                      {!this.state.enableEditNumber ?
                      <>
                      <span className="h5 text-black">{this.state.sampleNumber} </span>
                        <i onClick={() => this.setState({enableEditNumber : true})} className="fa fa-edit"></i>                                        
                      </>
                      : 
                        <input className="form-control" value={this.state.sampleNumber} onChange={(e) => this.setState({sampleNumber : e.target.value})}></input>
                      }
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">N. da OS: {this.state.Demand == null ? "":this.state.Demand.serviceOrder}</span>
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Responsável: {this.state.sampleData.personName}</span>
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Depósito: {this.state.sampleData.deposit}</span>
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Animal: {this.state.sampleData.animal}</span>
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Material Coletado: {this.state.sampleData.typeMaterial}</span>
                    </div>

                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Quantidade: {this.state.sampleData.quantity}</span>
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Quantidade de Vivos: {this.state.sampleData.quantityLiveMaterial}</span>
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Quantidade de Mortos: {this.state.sampleData.quantityDeadMaterial}</span>
                    </div>
                    
                  
                    {this.state.Trap != null?
                      <>
                        <div className="linha-form col-4 responsive-form-field-result-full">
                          <span className="h5 text-black">N. da Armadilha: {this.state.Trap.number}</span>
                        </div>
                        <div className="linha-form col-4 responsive-form-field-result-full">
                          <span className="h5 text-black">Tipo de armadilha: {this.state.Trap.trapTypeId}</span>
                        </div>
                      </>
                    :""}

                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Data da Coleta: {this.state.sampleData.dateCollected ? timeStampFormated(this.state.sampleData.dateCollected) : ""}</span>
                    </div>
                  </div>
                  </CardBody>
              </Card>:""}
              {/* formulario de preenchimendo dos resultados da amostra */}
              {sampleData !== '1' ? (
              <>
                <div className="row">
                  <div className="col-3 responsive-form-field-result-full">
                      <span className="h5 text-black">Amostra viável para análise?</span>
                      <Select 
                        onChange={(e) => {this.setState({isPossibleDiagnosis : e.value})}}    
                        defaultValue={{label:"Sim", value: true}}                                              
                        options={[
                          {label:"Sim", value: true},
                          {label:"Não", value: false}
                        ]} 
                      />
                  </div>
                  {this.state.isPossibleDiagnosis ? 
                    <>
                      <div className="col-3 responsive-form-field-result-full">
                          <span className="h5 text-black">Tipo de conservação</span>
                          <Select 
                            value = {this.state.ConservationTypes.filter(ct => ct.value == this.state.sampleConservationType)}
                            options={this.state.ConservationTypes}
                            onChange={(e) => {this.setState({sampleConservationType:e.value})} }
                          />
                      </div>
                      <div className="col-3 responsive-form-field-result-full">
                          <span className="h5 text-black">Grupo de animais envolvidos</span>
                          <Select 
                            key={this.state.selectedAnimalTypeGroup}
                            options={this.state.animalTypeGroups}
                            value={this.state.selectedAnimalTypeGroup !== '' ? this.state.animalTypeGroups.filter(obj => obj.value === this.state.selectedAnimalTypeGroup):null}
                            isClearable={true}
                            isDisabled={this.state.isDisableOrganization}
                            onChange={(e) => {if(e !== null){ this.selectedTypeGroup(e.value)}else if(!this.state.isDisableOrganization){this.selectedTypeGroup("00000000-0000-0000-0000-000000000000")}}}  
                          />
                      </div>
                      <div className="col-3 responsive-form-field-result-full">
                          <span className="h5">Animal</span>
                          <Select 
                            key={this.state.selectedAnimalGroup}
                            options={this.state.animalGroups} 
                            onChange={(e) => {if(e !== null){ this.selectedGroup(e.value)}else{this.selectedGroup("00000000-0000-0000-0000-000000000000")}}}
                            isClearable={true}
                            value={this.state.selectedAnimalGroup !== '' ? this.state.animalGroups.filter(obj => obj.value === this.state.selectedAnimalGroup) : null}
                            isDisabled={this.state.isDisableOrganization || this.state.selectedAnimalTypeGroup == '00000000-0000-0000-0000-000000000000'}
                          />
                      </div>

                    </>
                  :
                    <>
                        <div className="col-3 responsive-form-field-result-full">
                          <span className="h5">Justificativa de inviabilidade</span>
                          <Select 
                            options={this.state.JustificationDisposalTypes} 
                            onChange={(e) => this.addJustificationType(e)}
                            isMulti
                            
                          />
                      </div>
                      {this.state.sampleJustificationDisposalTypes.map(selectedJustificationType =>{
                        if(selectedJustificationType.name == "Outro"){
                          return(
                            <>
                              <div className="col-3 responsive-form-field-result-full">
                                <span className="h5">Descrição de justificativa</span>
                                <input className="form-control"  onChange={ (e) => selectedJustificationType.description = e.target.value} type="text" name="OtherDescription" id=""placeholder="Descrição"/>
                              </div>
                            </>                                
                          )

                        }                           
                      })}
                    </>
                  }
                  
                </div>
                <Card style={{"marginTop": "2%", "marginBottom": "2%", 'display': this.state.showResults ? showResult = "block": showResult = "none"}}>
                  <CardHeader>
                      <span className="h4" ><i className='fa fa-vial'></i> Amostra 1</span>
                  </CardHeader>
                  <CardBody className="responsive-card-result">
                    <Card style={{"marginTop": "2%", "marginBottom": "2%"}}>
                      <CardHeader>
                        <span className="h4"><i className='fa fa-microscope'></i> Identificação</span>
                      </CardHeader>
                      <CardBody>
                        {this.state.sampleIdentifications.map((identification, indexIdentification) => {
                          return (                            
                            <div className='row' style={{'display' : identification.Show === false ? 'none' : '' }} >                              
                              <div className="col-3 responsive-form-field-result-full">
                                  <span className="h5">Fase</span>
                                  <Select
                                    onChange={ (e) => this.handleChangeIdentification(e, indexIdentification)} 
                                    name="Stage" 
                                    id=""                                    
                                    value={options.filter(option => option.value == identification.Stage)}
                                    options={this.state.isDisableOrganization?groupedInvertebratesOptions:groupedOptions} />
                              </div>
                              {identification.Stage==="Mosquito Adulto"?
                              <>
                              <div className="col-2 responsive-form-field-result-full">
                                <span className="h5">Sexo</span>
                                <select className="form-control" name="Gender" id="" onChange={(e) => this.handleChangeIdentification(e, indexIdentification)}>
                                    <option value="" selected={identification.Gender == ""}>Selecione</option>
                                    <option value="Macho" selected={identification.Gender == "Macho"}>Macho</option>
                                    <option value="Fêmea" selected={identification.Gender == "Fêmea"}>Fêmea</option>                                                                             
                                </select>
                              </div>

                              <div className="col-2 responsive-form-field-result-medium">
                                  <span className="h5">Qtd Vivos</span>
                                  <input className="form-control" value={identification.QuantityAlive} onChange={ (e) => this.handleChangeIdentification(e, indexIdentification)}  type="number" name="QuantityAlive"placeholder="0"/>
                              </div>

                              <div className="col-2 responsive-form-field-result-medium">
                                <span className="h5">Qtd Mortos</span>
                                <input className="form-control" value={identification.QuantityDead}  onChange={ (e) => this.handleChangeIdentification(e, indexIdentification)} type="number" name="QuantityDead" id=""placeholder="0"/>
                              </div> </>:""}
                              <div className="col-2 responsive-form-field-result-full">
                                  <span className="h5">Qtd Total</span>
                                  <input className="form-control" value={identification.Quantity} onChange={ (e) => this.handleChangeIdentification(e, indexIdentification)}  type="number" name="Quantity"placeholder="0"/>
                              </div>
                              <div className="col-1 responsive-form-field-result-full">
                              <span className="h5">&nbsp;</span>
                                <button data-tip data-for="deleteResult" className="btn btn-warning responsive-button-result" onClick={() => this.removeIdentification(indexIdentification)}><i className='fa fa-trash'></i></button>  
                                <ReactTooltip effect="solid" type="warning" id="deleteResult">
                                    <span>Apagar resultado</span>
                                </ReactTooltip>
                              </div>                                
                            </div>                            
                          )
                        })}
                        {this.state.sampleIdentifications.length > 1 ?
                            
                            <>
                            <div className="row">
                              <div className="col-3 responsive-form-field-result-medium">
                                  <span className="h5">Qtd Somada Vivos</span>
                                  <input className="form-control" value={QuantityAliveSoma} disabled type="number" name="QuantityAlive"placeholder="0"/>
                              </div>

                              <div className="col-3 responsive-form-field-result-medium">
                                <span className="h5">Qtd Somada Mortos</span>
                                <input className="form-control" value={QuantityDeadSoma}disabled  type="number" name="QuantityDead" id=""placeholder="0"/>
                              </div>
                              <div className="col-3 responsive-form-field-result-full">
                                  <span className="h5">Qtd Somada Total</span>
                                  <input className="form-control" value={QuantitySoma} disabled  type="number" name="Quantity"placeholder="0"/>
                              </div>   
                            </div>
                            </> 
                          :null}
                        <div className="row mt-4 mb-4"> 
                          <div className={this.state.sampleIdentifications.length > 1 ? "col-6 responsive-form-field-result-full" : "col-12 responsive-form-field-result-full"} >
                              <button className="btn btn-info responsive-button-result" onClick={() => this.addIdentification()}>+ Adicionar Identificação</button>
                          </div>
                        </div>
                      </CardBody>
                      
                    </Card>
                    <Card style={{"marginTop": "2%", "marginBottom": "2%"}}>
                      <CardHeader>
                        <span className="h4"><i className='fa fa-vials'></i> Classificação</span>
                      </CardHeader>
                      <CardBody>
                        <div className="row">
                          <div className={`col-6 responsive-form-field-result-full`}>
                            <span className="h5">Gênero</span>
                            <Select 
                              options={this.state.animalGenres} 
                              onChange={(e) => {if(e !== null){ this.selectedGenre(e.value)}else{this.selectedGenre("00000000-0000-0000-0000-000000000000")}}}
                              isClearable={true}
                              value={this.state.selectedAnimalGenre !== '' ? this.state.animalGenres.filter(obj => obj.value === this.state.selectedAnimalGenre): null }
                              isDisabled={this.state.selectedAnimalGroup == "00000000-0000-0000-0000-000000000000"}
                            />
                          </div>

                          <div className={`col-6 responsive-form-field-result-full`}>
                            <span className="h5">Espécie</span>
                            <Select 
                              key={this.state.selectedAnimalSpecie}
                              options={this.state.animalSpecies} 
                              onChange={(e) => {if(e !== null) {this.setState({selectedAnimalSpecie:e.value})} else{this.setState({selectedAnimalSpecie: "00000000-0000-0000-0000-000000000000"})}}}
                              isClearable={true}
                              value={this.state.selectedAnimalSpecie !== '' ? this.state.animalSpecies.filter(obj => obj.value === this.state.selectedAnimalSpecie): null }
                              isDisabled={this.state.selectedAnimalGenre == '00000000-0000-0000-0000-000000000000'}
                            />
                          </div>                          
                        </div>
                        {this.state.sampleClassification.map((classification,indexClassification)=>{
                          return(
                            <>
                              <div className="row">
                                <div className="col-6 responsive-form-field-result-full">
                                    <span className="h5">Nome do laboratório credenciado</span>
                                    <Select 
                                      key ={classification.LaboratoryId}
                                      value={this.state.Laboratories.filter(l => l.value == classification.LaboratoryId)}
                                      options={this.state.Laboratories} 
                                      name={"LaboratoryId"}
                                      onChange={(e) => this.handleFowardedSample(e, indexClassification)}
                                    />
                                </div>

                                <div className="col-6  responsive-form-field-result-full">
                                    <span className="h5">Número da amostra no laboratório externo</span>
                                    <input type="text" className="form-control" placeholder="Número da amostra" name="externSampleNumber" value={classification.externSampleNumber} onChange={(e) => {this.handleFowardedSample(e, indexClassification)}}/>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12 responsive-form-field-result-full">
                                    <span className="h5">Descrição de encaminhamento</span>
                                    <textarea className="form-control" placeholder="Motivo de encaminhamento" name="description" value={classification.description} onChange={(e) => {this.handleFowardedSample(e, indexClassification)}}/>
                                </div>
                              </div>
                              {classification.exams.map((exam, examIndex) => {
                                return (
                                  <>
                                  <div className="row">
                                    <div className="col-6 responsive-form-field-result-full">
                                      <span className="h5">Exame realizado</span>
                                      <select className="form-control" name="examId" id="" defaultValue={exam.examId} onChange={(e) => this.handleFowardedSample(e, indexClassification, "exams", examIndex)}>
                                          <option value="">Selecione</option>
                                          {this.state.identificationsExams.map((exam, id) =>{
                                            return (
                                              <option value={exam.id}>{exam.name}</option>
                                            )
                                          })}
                                      </select>
                                    </div>

                                    <div className="col-6 responsive-form-field-result-full">
                                      <span className="h5">Técnica Utilizada</span>
                                      <select className="form-control" name="techniqueId" id="" defaultValue={exam.techniqueId} onChange={(e) =>this.handleFowardedSample(e, indexClassification, "exams", examIndex)}>
                                          <option value="">Selecione</option>
                                          {this.state.techniques.map((technique, id) =>{
                                            return (
                                              <option value={technique.id}>{technique.name}</option>
                                            )
                                          })}
                                      </select>
                                    </div>
                                  </div>
                                  
                                  </>
                                )
                                
                              })}
                              
                              <div className="row mt-4 mb-4">
                                <div className={classification.exams.length > 1 ? "col-6  responsive-form-field-result-full" : "col-12  responsive-form-field-result-full"}>
                                    <button className="btn btn-info responsive-button-result" onClick={() => this.addClassificationExam(indexClassification)}>+ Adicionar Exame</button>
                                </div>
                                {classification.exams.length > 1 ?
                                  <div className="col-6 responsive-form-field-result-full">
                                    <button className="btn btn-warning responsive-button-result" onClick={() => this.removeClassificationExam(indexClassification)}><i className='fa fa-trash'></i> Remover Exame</button>
                                  </div>    
                                :null}
                              </div>
                            </>
                          )
                        })}
                        
                        <div className="row">
                          <div className={this.state.sampleClassification.length > 1 ? "col-6  responsive-form-field-result-full" : "col-12  responsive-form-field-result-full"}>
                              <button className="btn btn-info responsive-button-result" onClick={() => this.addLabForward()}>
                                + Adicionar Encaminhamento
                              </button>
                          </div>                                          
                          {this.state.sampleClassification.length > 1 ?
                            <div className="col-6 responsive-form-field-result-full">
                              <button className="btn btn-warning responsive-button-result" onClick={() => this.removeLabForward()}><i className='fa fa-trash'></i> Remover Encaminhamento</button>
                            </div>    
                          :null}
                        </div>
                      </CardBody>  
                    </Card>
                    {this.state.isDisableOrganization?"":
                    <Card style={{"marginTop": "2%", "marginBottom": "2%"}}>
                      <CardHeader>
                        <span className="h4" ><i className='fa fa-diagnoses'></i> Diagnóstico</span>
                      </CardHeader>

                      <CardBody>
                        {this.state.sampleDiagnosis.map((diagnostic, diagnosticIndex) => {
                          return (
                            <>
                              <div className="row">
                                <div className="col-6  responsive-form-field-result-full">
                                    <span className="h5">Nome do laboratório credenciado</span>
                                    <Select 
                                      options={this.state.Laboratories} 
                                      key={diagnostic.LaboratoryId}   
                                      name="ExternLaboratoryId"                                            
                                      onChange={(e) => {this.handleChangeDiagnosis(diagnosticIndex, e)}}  
                                      value={this.state.Laboratories.filter(laboratory => laboratory.value == diagnostic.LaboratoryId)}  
                                    />
                                </div>
                                <div className="col-6  responsive-form-field-result-full">
                                    <span className="h5">Número da amostra no laboratório externo</span>
                                    <input type="text" className="form-control" placeholder="Número amostra" name="externSampleNumber" value={diagnostic.externSampleNumber} onChange={(e)=>{this.handleChangeDiagnosis(diagnosticIndex, e)}}/>
                                </div>                                                  
                              </div>
                              <div className="row">
                                <div className="col-12">
                                    <span className="h5">Descrição de encaminhamento</span>
                                    <textarea className="form-control" placeholder="Motivo de encaminhamento" name="description" value={diagnostic.description} onChange={(e) => {this.handleChangeDiagnosis(diagnosticIndex, e)}}/>
                                </div>
                              </div>
                              {diagnostic.exams.map((exam, examIndex) => {
                                return (
                                  <div className="row">
                                    <div className="col-6 responsive-form-field-result-full">
                                      <span className="h5">Exame realizado</span>
                                      <select className="form-control" name="examId" id="" defaultValue={exam.examId} onChange={(e) => this.handleChangeDiagnosis(diagnosticIndex, e, "exams", examIndex)}>
                                          <option value="">Selecione</option>
                                          {this.state.diagnosticsExams.map((exam, id) =>{
                                            return (
                                              <option value={exam.id}>{exam.name}</option>
                                            )
                                          })}
                                      </select>
                                    </div>

                                    <div className="col-6 responsive-form-field-result-full">
                                      <span className="h5">Técnica Utilizada</span>
                                      <select className="form-control" name="techniqueId" id="" defaultValue={exam.techniqueId} onChange={(e) =>this.handleChangeDiagnosis(diagnosticIndex, e, "exams", examIndex)}>
                                          <option value="">Selecione</option>
                                          {this.state.techniques.map((technique, id) =>{
                                            return (
                                              <option value={technique.id}>{technique.name}</option>
                                            )
                                          })}
                                      </select>
                                    </div>
                                  </div>
                                )
                              })}

                              <div className="row mt-4 mb-4">
                                <div className={diagnostic.exams.length > 1 ? "col-6 responsive-form-field-result-full" : "col-12 responsive-form-field-result-full"}>
                                    <button className="btn btn-info responsive-button-result" onClick={() => this.addDiagnosisExam(diagnosticIndex)}>+ Adicionar Exame e Técnica</button>
                                </div>
                                {diagnostic.exams.length > 1 ?
                                  <div className="col-6 responsive-form-field-result-full">
                                    <button className="btn btn-warning responsive-button-result" onClick={() => this.removeDiagnosisExam(diagnosticIndex)}><i className='fa fa-trash'></i> Remover Exame e Técnica</button>
                                  </div>    
                                :null}
                              </div>

                              <div className="row mb-5">
                                <div className="col-4 responsive-form-field-result-full">
                                  <span className="h5 text-black">Diagnóstico</span>
                                  <select className="form-control" name="diagnostic" value={diagnostic.diagnostic} onChange={(e)=>{this.handleChangeDiagnosis(diagnosticIndex, e)}}>                                                       
                                    <option value={false}>Selecione</option>                              
                                    <option value={"a4620cb6-85da-4543-a399-12a35ee035a5"}>Aedes aegypti</option>                                        
                                  </select>
                                </div>

                                <div className="col-4 responsive-form-field-result-full">
                                  <span className="h5 text-black">Resultado</span>
                                  <select className="form-control" name="result" value={diagnostic.result} onChange={(e)=>{this.handleChangeDiagnosis(diagnosticIndex, e)}}>
                                    <option>Selecione</option>                                                                                  
                                    <option>Negativo</option>
                                    <option>Positivo</option>
                                  </select>
                                </div>

                                <div className="col-4 responsive-form-field-result-full">
                                <span className="h5">Tipo de vírus</span>
                                <Select 
                                  key={this.state.selectedVirusType}
                                  options={this.state.virusTypes} 
                                  onChange={(e) => {this.setState({selectedVirusType: e !== null ? e.value : '00000000-0000-0000-0000-000000000000'})}}
                                  isClearable={true}
                                  value={this.state.selectedVirusType !== '00000000-0000-0000-0000-000000000000' ? this.state.virusTypes.filter(obj => obj.value === this.state.selectedVirusType) : null}
                                  isDisabled={this.state.selectedAnimalGenre == '00000000-0000-0000-0000-000000000000'}
                                />
                              </div>


                              </div>
                             
                              

                            </>
                          )
                        })}

                        <div className="row">
                          <div className={this.state.sampleDiagnosis.length > 1 ? "col-6 responsive-form-field-result-full" : "col-12 responsive-form-field-result-full"}>
                              <button className="btn btn-info responsive-button-result" disabled={!this.state.isPossibleDiagnosis} onClick={() => this.addDiagnosis()}>+ Adicionar Encaminhamento</button>
                          </div>
                          {this.state.sampleDiagnosis.length > 1 ?
                            <div className="col-6 responsive-form-field-result-full">
                              <button className="btn btn-warning responsive-button-result" onClick={() => this.removeDiagnosis()}><i className='fa fa-trash'></i> Remover Encaminhamento</button>
                            </div>    
                          :null}
                        </div>
                      </CardBody>
                    </Card>}
                  </CardBody>
                </Card>
                <div className="row mt-4">
                  {!this.state.showResults ? (
                    <div className="col-4  responsive-form-field-result-full">
                      <button className="btn btn-info responsive-button-result" disabled={!this.state.isPossibleDiagnosis} onClick={() => {this.setState({showResults: true})}}>+ Adicionar resultado</button>
                    </div>
                  ) : null}

                  <div className="col-4 responsive-form-field-result-medium">
                      <Link to="/admin/amostrasColetadas">                                
                      <button className="form-control btn btn-warning responsive-button-result">Voltar</button>
                      </Link>
                  </div>

                  <div className="col-4 responsive-form-field-result-medium">
                      <button className="btn btn-primary responsive-button-result" disabled={!this.state.showResults && this.state.isPossibleDiagnosis} onClick={() => this.sendSampleResult(false)}>Salvar</button>
                  </div>

                  {this.state.showResults ? (
                    <div className="col-4 responsive-form-field-result-full">
                      <Link to={{pathname:"/admin/AddSampleCovid", data:this.state.sampleData}}>                                                        
                          <button onClick={() => this.sendSampleResult(true)} className="btn btn-warning responsive-button-result">Salvar e cadastrar nova amostra</button>
                      </Link>
                    </div> 
                  ) : null}                
                </div>
              </>
            ): null}
            </Container>
          </Card>
        </Container>
      </>
    );
  }

}
export default AddSampleResult;