import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  deleteTrapHistory,
  getTrapByNumber,
  updateHistories,
} from "../../../../services/api/Trap";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
} from "../../../../components/Accordion/Accordion";
import moment from "moment";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { selectComponentStyles } from "../../../../services/utils/globalFunctions";
import ReactTooltip from "react-tooltip";

const useTrapHistoriesModal = (trapNumber, isOpen) => {
  const [openedAccordions, setOpenedAccordions] = React.useState([]);
  const [trapHistoryToDeleteData, setTrapHistoryToDeleteData] =
    React.useState(null);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    React.useState(false);

  React.useEffect(() => {
    if (!isOpen || !trapNumber || trapNumber === "") return;

    fetchTrapByNumberData(trapNumber);
  }, [isOpen, trapNumber]);

  const fetchTrapByNumberData = async (trapNumber) => {
    if (!trapNumber || trapNumber === "") return;

    try {
      const { data, status } = await getTrapByNumber(trapNumber);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos detalhes da armadilha"
        );

      const orderedHistoriesByStatusDate = data.histories.sort(
        (a, b) => new Date(b.statusDate) - new Date(a.statusDate)
      );

      const accordionsData = orderedHistoriesByStatusDate.map(
        ({
          createAt,
          id,
          statusDate,
          trapWasInstalled,
          trapWasRead,
          user,
          trapWasRemoved,
          wasCollectedSample,
          latitude,
          longitude,
        }) => {
          const projectsText =
            data && data.projects
              ? data.projects.map(({ name }) => name).join(", ")
              : "";

          return {
            createAt,
            id,
            opened: false,
            statusDate: moment(statusDate).format("YYYY-MM-DD HH:mm"),
            trapWasInstalled,
            trapWasRead,
            trapWasRemoved,
            trapTypeName: ARMADILHA_INFO[data.trapTypeName].apelido,
            wasCollectedSample,
            latitude,
            longitude,
            username:
              !user || !user.person ? "Não encontrado" : user.person.name,
            position: [latitude, longitude],
            isMapOpen: false,
            mapZoom: 16,
            projectsText,
          };
        }
      );

      setOpenedAccordions(accordionsData);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error.message);
      }
    }
  };

  const handleAccordionChange = (accordionIndex, propertyName, newValue) => {
    setOpenedAccordions((previousValues) => {
      const newValues = [...previousValues];

      newValues[accordionIndex] = {
        ...newValues[accordionIndex],
        [propertyName]: newValue,
      };

      return newValues;
    });
  };

  const handleDeleteTrapHistory = async () => {
    if (
      !trapHistoryToDeleteData ||
      trapHistoryToDeleteData.index < 0 ||
      !trapHistoryToDeleteData.id ||
      trapHistoryToDeleteData.id === "" ||
      trapHistoryToDeleteData.id === "00000000-0000-0000-0000-000000000000"
    )
      return;

    const installHistories = openedAccordions.filter(
      ({ trapWasInstalled }) => trapWasInstalled
    ).length;

    if (installHistories === 0) {
      toast.error(
        "A armadilha precisa de ao menos um histórico de instalação. Altere um dos históricos para que o campo 'Instalada' tenha o valor 'Sim'."
      );

      return;
    }

    if (openedAccordions.length === 1) {
      toast.error("Não é possível apagar o único histórico da armadilha.");

      return;
    }

    try {
      const { status } = await deleteTrapHistory(trapHistoryToDeleteData.id);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao excluir o histórico da armadilha. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      toast.success("Histórico excluído com sucesso!");

      setOpenedAccordions((previousValues) => {
        const newValues = [...previousValues];
        newValues.splice(trapHistoryToDeleteData.index, 1);

        return newValues;
      });
      setIsConfirmDeleteModalOpen(false);
      setTrapHistoryToDeleteData(null);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error.message);
      }
    }
  };

  const handleUpdateTrapHistory = async (index) => {
    if (index < 0) return;

    const installHistories = openedAccordions.filter(
      ({ trapWasInstalled }) => trapWasInstalled
    ).length;

    if (installHistories === 0) {
      toast.error(
        "A armadilha precisa de ao menos um histórico de instalação. Altere um dos históricos para que o campo 'Instalada' tenha o valor 'Sim'."
      );

      return;
    }

    try {
      const { status } = await updateHistories([openedAccordions[index]]);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao atualizar o histórico da armadilha. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      toast.success("Histórico atualizado com sucesso!");
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error.message);
      }
    }
  };

  const toggleConfirmDeleteModal = (trapHistoryToDelete) => {
    setIsConfirmDeleteModalOpen((previousValue) => {
      if (trapHistoryToDelete) setTrapHistoryToDeleteData(trapHistoryToDelete);

      return !previousValue;
    });
  };

  const booleanOptions = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  return {
    booleanOptions,
    handleAccordionChange,
    handleDeleteTrapHistory,
    handleUpdateTrapHistory,
    isConfirmDeleteModalOpen,
    openedAccordions,
    toggleConfirmDeleteModal,
  };
};

const TrapHistoriesModal = ({ isOpen, toggle, trapNumber }) => {
  const {
    booleanOptions,
    handleAccordionChange,
    handleDeleteTrapHistory,
    handleUpdateTrapHistory,
    isConfirmDeleteModalOpen,
    openedAccordions,
    toggleConfirmDeleteModal,
  } = useTrapHistoriesModal(trapNumber, isOpen);

  const styles = {
    list: {
      listStyleType: "none",
      padding: "0px",
      margin: "0px",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "1rem",
      marginBottom: "1rem",
    },
    accordionContent: {
      padding: "1rem 1.5rem",
      fontSize: ".9rem",
      borderBottom: "1px solid #f0f1f3",
    },
    trueContent: {
      color: "#3b6bca",
    },
    actionButtonsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "1rem",
    },
  };

  return (
    <>
      <Modal
        isOpen={isConfirmDeleteModalOpen}
        toggle={toggleConfirmDeleteModal}
      >
        <ModalHeader className="pb-0"></ModalHeader>
        <ModalBody className="pt-0 pb-4">
          <span>
            Ao clicar no botão "Apagar" o histórico será{" "}
            <span style={{ fontWeight: "bold" }}>EXCLUÍDO</span> do sistema.
            Você tem certeza que deseja fazê-lo?
          </span>
        </ModalBody>
        <ModalFooter style={{ borderTop: "1px solid #f0f1f3" }}>
          <Button style={{ width: "130px" }} color="secondary" onClick={toggleConfirmDeleteModal}>
            Fechar
          </Button>
          <Button
            style={{ width: "130px" }}
            color="warning"
            onClick={handleDeleteTrapHistory}
          >
            Apagar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="lg" isOpen={isOpen} toggle={toggle}>
        <ModalHeader className="pb-0">
          <span className="h2">
            Históricos da armadilha (
            <span style={styles.trueContent}>{trapNumber}</span>)
          </span>
          <p style={{ fontSize: "14px" }}>
            Visualize e edite toda a história de uma armadilha no sistema.
          </p>
        </ModalHeader>
        <ModalBody className="pt-0 pb-4">
          <Accordion>
            {openedAccordions &&
              openedAccordions.map(
                (
                  {
                    createAt,
                    id,
                    username,
                    statusDate,
                    trapWasInstalled,
                    trapWasRead,
                    trapWasRemoved,
                    trapTypeName,
                    wasCollectedSample,
                    position,
                    projectsText,
                  },
                  index
                ) => {
                  const isAccordionOpen = openedAccordions[index].opened;

                  return (
                    <AccordionItem key={id} style={{ padding: "0px" }}>
                      <AccordionHeader
                        isOpen={isAccordionOpen}
                        onClick={() =>
                          handleAccordionChange(
                            index,
                            "opened",
                            !isAccordionOpen
                          )
                        }
                      >
                        <span className="h3 mb-0">
                          {moment(statusDate).format("DD/MM/YYYY HH:mm")} -
                          Instalada:{" "}
                          {trapWasInstalled === true ? (
                            <span style={styles.trueContent}>Sim</span>
                          ) : (
                            "Não"
                          )}{" "}
                          / Lida:{" "}
                          {trapWasRead === true ? (
                            <span style={styles.trueContent}>Sim</span>
                          ) : (
                            "Não"
                          )}{" "}
                          / Desinstalada:{" "}
                          {trapWasRemoved === true ? (
                            <span style={styles.trueContent}>Sim</span>
                          ) : (
                            "Não"
                          )}{" "}
                        </span>
                      </AccordionHeader>
                      <AccordionContent
                        isOpen={isAccordionOpen}
                        style={styles.accordionContent}
                      >
                        <ul style={styles.list}>
                          <li style={{ gridColumn: "1 / 3" }}>
                            <span
                              style={{ display: "block" }}
                              className="h4 mb-0"
                            >
                              Usuário responsável pela visita:
                            </span>
                            <input
                              className="form-control"
                              disabled={true}
                              type="text"
                              value={username}
                            />
                          </li>
                          <li style={{ gridColumn: "1 / 3" }}>
                            <span
                              style={{ display: "block" }}
                              className="h4 mb-0"
                            >
                              Tipo de armadilha:
                            </span>
                            <input
                              className="form-control"
                              disabled={true}
                              type="text"
                              value={trapTypeName}
                            />
                          </li>
                          <li>
                            <span className="h4 mb-0">Data da atividade:</span>
                            <input
                              className="form-control"
                              type="datetime-local"
                              value={statusDate}
                              onChange={({ target: { value } }) =>
                                handleAccordionChange(
                                  index,
                                  "statusDate",
                                  value
                                )
                              }
                            />
                          </li>
                          <li>
                            <span className="h4 mb-0">Instalada:</span>
                            <Select
                              styles={selectComponentStyles}
                              placeholder={"Selecione"}
                              isClearable={false}
                              options={booleanOptions}
                              value={booleanOptions.find(
                                ({ value }) => value === trapWasInstalled
                              )}
                              onChange={(e) =>
                                handleAccordionChange(
                                  index,
                                  "trapWasInstalled",
                                  e.value
                                )
                              }
                            />
                          </li>
                          <li>
                            <span className="h4 mb-0">Lida:</span>
                            <Select
                              styles={selectComponentStyles}
                              placeholder={"Selecione"}
                              isClearable={false}
                              options={booleanOptions}
                              value={booleanOptions.find(
                                ({ value }) => value === trapWasRead
                              )}
                              onChange={(e) =>
                                handleAccordionChange(
                                  index,
                                  "trapWasRead",
                                  e.value
                                )
                              }
                            />
                          </li>
                          <li>
                            <span className="h4 mb-0">Desinstalada:</span>
                            <Select
                              styles={selectComponentStyles}
                              placeholder={"Selecione"}
                              isClearable={false}
                              options={booleanOptions}
                              value={booleanOptions.find(
                                ({ value }) => value === trapWasRemoved
                              )}
                              onChange={(e) =>
                                handleAccordionChange(
                                  index,
                                  "trapWasRemoved",
                                  e.value
                                )
                              }
                            />
                          </li>
                          <li>
                            <span className="h4 mb-0">Latitude:</span>
                            <input
                              className="form-control"
                              type="text"
                              value={position[0]}
                              disabled={true}
                            />
                          </li>
                          <li>
                            <span className="h4 mb-0">Longitude:</span>
                            <input
                              className="form-control"
                              type="text"
                              value={position[1]}
                              disabled={true}
                            />
                          </li>
                          <li>
                            <span className="h4 mb-0">Projetos:</span>
                            <input
                              className="form-control"
                              type="text"
                              value={projectsText}
                              disabled={true}
                            />
                          </li>
                          <li>
                            <span className="h4 mb-0">
                              Data de envio{" "}
                              <i
                                className="fa fa-info"
                                data-tip
                                data-for="createAt"
                              ></i>
                              :
                              <div>
                                <ReactTooltip
                                  effect="solid"
                                  type="info"
                                  id="createAt"
                                >
                                  Representa quando o histórico da armadilha
                                  entrou no sistema <br />
                                  por sincronização do aplicativo ou pela
                                  digitação do formulário.
                                </ReactTooltip>
                              </div>
                            </span>
                            <input
                              className="form-control"
                              type="datetime-local"
                              value={moment(createAt)
                                .subtract(3, "hours")
                                .format("YYYY-MM-DD HH:mm")}
                              disabled={true}
                            />
                          </li>
                        </ul>
                        <div style={styles.actionButtonsContainer}>
                          <Button
                            color="primary"
                            onClick={() => handleUpdateTrapHistory(index)}
                            style={{ margin: "0px" }}
                          >
                            <i className="fa fa-check"></i> Salvar alterações
                          </Button>
                          <Button
                            color="warning"
                            onClick={() =>
                              toggleConfirmDeleteModal({
                                index,
                                id,
                              })
                            }
                            style={{ margin: "0px" }}
                          >
                            <i className="fa fa-trash"></i> Apagar histórico
                          </Button>
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  );
                }
              )}
          </Accordion>
        </ModalBody>
        <ModalFooter style={{ borderTop: "1px solid #f0f1f3" }}>
          <Button style={{ width: "130px" }} color="secondary" onClick={toggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TrapHistoriesModal;
