import request from '../utils/request';
//import request from "../utils/requestTests/requestTestVisits"

export function getSincronizationsLog(organization = null, startDate = null, endDate = null){
  var startDateAux = new Date();
  var endDateAux = new Date();
  
  startDateAux.setDate(startDateAux.getDate() - 15);

  if(startDate) startDateAux = startDate;
  if(endDate) endDateAux = endDate;

  var informationsData = {
    organizationId: organization,
    startDate: startDateAux,
    endDate: endDateAux
  };

  return request({
    url: '/Sincronization/GetSincronizationsLog',
    method: 'post',
    data: informationsData
  });
}
