import React from "react";
import DataTable from "react-data-table-component";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import moment from "moment";

const VisitsFromLogsModal = ({ forms, setForms }) => {
  const tableColumns = [
    {
      name: "Tipo",
      cell: (row) => {
        if (row.trapType) {
          return (
            <>
              {row.trapType}
              <i>
                <img
                  style={{ width: "30px" }}
                  src={ARMADILHA_INFO[row.trapTypeName].principal_icon}
                  alt=""
                />
              </i>
            </>
          );
        }

        return "Não informado";
      },
      sortable: true,
    },
    {
      name: "Número",
      cell: (row) => {
        if (row.number) {
          return row.number;
        }

        return "Não informado";
      },
      sortable: true,
    },
    {
      name: "Geradas amostras",
      selector: "wasSamplesGenerated",
      sortable: true,
      cell: (v) => (v.wasSamplesGenerated ? "Sim" : "Não"),
    },
    {
      name: "Número amostras",
      selector: "generatedSamplesNumbers",
      sortable: true,
      cell: (v) =>
        v.generatedSamplesNumbers
          ? v.generatedSamplesNumbers.length === 0
            ? "-"
            : v.generatedSamplesNumbers.join(", ")
          : "Não informado",
    },
    {
      name: "Em condições de uso",
      selector: "isReadableCondition",
      sortable: true,
      cell: (v) => (v.isReadableCondition ? "Sim" : "Não"),
    },
  ];

  const generateTrapsData = (traps) => {
    if (!traps) return;

    const installedTraps = [];
    const readedTraps = [];
    const uninstalledTraps = [];

    Object.keys(ARMADILHA_INFO).forEach((trapType) => {
      if (traps[trapType] && traps[trapType].length > 0) {
        for (let i = 0; i < traps[trapType].length; i++) {
          const actualTrap = traps[trapType][i];

          const trapData = {
            number: actualTrap.numeroArmadilha,
            wasSamplesGenerated: actualTrap.amostras,
            generatedSamplesNumbers: !actualTrap.amostras
              ? []
              : actualTrap.amostrasColetadas.map(
                  ({ numeroDaAmostra }) => numeroDaAmostra
                ),
            isReadableCondition: actualTrap.isReadableCondition,
            trapType: ARMADILHA_INFO[trapType]
              ? ARMADILHA_INFO[trapType].apelido
              : "Não encontrado",
            trapTypeName: trapType,
          };

          if (actualTrap.armadilhaInstaladaNaVisita)
            installedTraps.push(trapData);

          if (actualTrap.armadilhaLidaNaVisita) readedTraps.push(trapData);

          if (actualTrap.armadilhaRetirada) uninstalledTraps.push(trapData);
        }
      }
    });

    return {
      installedTraps,
      readedTraps,
      uninstalledTraps,
    };
  };

  return (
    <div>
      {forms && forms.length > 0 ? (
        forms.map((visit, index) => {
          const visitTraps = generateTrapsData(visit.armadilha);
          var visitSituation = visit.VisitSituation
            ? visit.VisitSituation
            : "Não informado";
          var address = visit.Address
            ? `${visit.Address.localDoImovel} ${visit.Address.logradouro}, ${visit.Address.numero}`
            : "Não informado";
          var finalTime = visit.FinalTime
            ? moment(visit.FinalTime).format("HH:mm")
            : "Não informado";
          var initialTime = visit.InitialTime
            ? moment(visit.InitialTime).format("HH:mm")
            : "Não informado";
          var date = visit.ActivityDate
            ? moment(visit.ActivityDate).format("DD/MM/YYYY")
            : "Não informado";
          return (
            <div style={{ margin: 15 }}>
              <hr />
              {forms.length >= 1 ? (
                <p style={{ textAlign: "center" }}>
                  <span
                    style={{
                      display: "block",
                      fontSize: 22,
                      fontWeight: "bold",
                    }}
                  >
                    Visita {index + 1}
                  </span>
                </p>
              ) : (
                <></>
              )}
              <span style={{ display: "block" }}>
                <span style={{ fontWeight: "bold" }}>Situação:</span>{" "}
                {visitSituation}
              </span>
              <span style={{ display: "block" }}>
                <span style={{ fontWeight: "bold" }}>Endereço:</span> {address}
              </span>
              <span style={{ display: "block" }}>
                <span style={{ fontWeight: "bold" }}>Data da atividade:</span>{" "}
                {date}
              </span>
              <span style={{ display: "block" }}>
                <span style={{ fontWeight: "bold" }}>Hora de início:</span>{" "}
                {moment(visit.InitialTime).add(3, "hours").format("HH:mm")}
              </span>
              <span style={{ display: "block" }}>
                <span style={{ fontWeight: "bold" }}>Hora de término:</span>{" "}
                {moment(visit.FinalTime).add(3, "hours").format("HH:mm")}
              </span>
              <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "1rem",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Armadilhas instaladas:
                </span>
              </div>
              <DataTable
                noHeader
                defaultSortField="name"
                defaultSortAsc={true}
                highlightOnHover
                responsive
                columns={tableColumns}
                data={visitTraps.installedTraps}
                noDataComponent={""}
              />
              <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "1rem",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Armadilhas lidas:</span>
              </div>
              <DataTable
                noHeader
                defaultSortField="name"
                defaultSortAsc={true}
                highlightOnHover
                responsive
                columns={tableColumns}
                data={visitTraps.readedTraps}
                noDataComponent={""}
              />
              <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "1rem",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Armadilhas desinstaladas:
                </span>
              </div>
              <DataTable
                noHeader
                defaultSortField="name"
                defaultSortAsc={true}
                highlightOnHover
                responsive
                columns={tableColumns}
                data={visitTraps.uninstalledTraps}
                noDataComponent={""}
              />
            </div>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default VisitsFromLogsModal;
