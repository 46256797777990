import { useContextSelector } from "use-context-selector";
import { MapContext } from "../Contexts/mapContext";

const useMap = () => {
  const filteredTrapPosition = useContextSelector(
    MapContext,
    (context) => context.filteredTrapPosition
  );

  return {
    filteredTrapPosition,
  };
};

export default useMap;
