import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import ReactTooltip from "react-tooltip";
import DataTable from "react-data-table-component";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";
import moment from "moment";

import { timeStampFormatedMongo } from "../../../../services/utils/format";
import VisitsFromLogsModal from "../Modals/VisitsFromLogsModal";
import { getSincronizationsLog } from "../../../../services/api/Sincronization";

const SincronizationTable = ({
  headerText,
  pageFiltersTeams,
  pageFiltersEmployees,
  employeesList,
  teamsList,
  startDate,
  endDate,
  organizationId,
}) => {
  const [sincronizationsProcessed, setSincronizationsProcessed] =
    React.useState([]);
  const [sincronizationsWithError, setSincronizationsWithError] =
    React.useState([]);
  const [forms, setForms] = React.useState(null);
  const [generatedAt, setGeneratedAt] = React.useState("");
  const [dataToExport, setDataToExport] = React.useState([]);
  const [isVisitsModalOpen, setIsVisitsModalOpen] = React.useState(false);
  const contentToPrintRef = React.useRef(null);

  React.useEffect(() => {
    const splitedActualDate = moment().format("DD/MM/YYYY HH:mm").split(" ");
    setGeneratedAt(`${splitedActualDate[0]} às ${splitedActualDate[1]}`);
  }, []);

  React.useEffect(() => {
    sincronizationsList(
      organizationId,
      employeesList,
      teamsList,
      pageFiltersTeams,
      pageFiltersEmployees,
      startDate,
      endDate
    );
  }, [
    organizationId,
    pageFiltersTeams,
    pageFiltersEmployees,
    startDate,
    endDate,
  ]);

  React.useEffect(() => {
    const formattedData = [
      ...sincronizationsProcessed,
      ...sincronizationsWithError,
    ].map((sync) => {
      console.log(filteredEmployees);
      const employee = filteredEmployees.find(
        (employee) => employee.userId === sync.whoSyncId
      );

      return {
        USUARIO: employee ? employee.employeeName : "Não encontrado",
        QTD_VISITAS: sync.qttVisits,
        QTD_ARMADILHAS: sync.qttTraps,
        DATA_SINCRONIZACAO: timeStampFormatedMongo(
          sync.dateOfSincronization,
          true
        ),
        ...{
          [sync.status === "Processado" ? "DATA_PROCESSADO" : "ERRO"]:
            sync.status === "Processado"
              ? timeStampFormatedMongo(sync.dateOfSincronizationProcessed, true)
              : "Procure o suporte para que o erro seja analisado.",
        },
      };
    });

    setDataToExport(formattedData);
  }, [sincronizationsProcessed, sincronizationsWithError]);

  const sincronizationsList = (
    organizationId,
    employeesList,
    teamsList,
    pageFiltersTeams,
    pageFiltersEmployees,
    startDate,
    endDate
  ) => {
    const startDateWithTime = moment(startDate).format("YYYY-MM-DD 00:00:00");
    const endDateWithTime = moment(endDate).format("YYYY-MM-DD 23:59:59");

    getSincronizationsLog(organizationId, startDateWithTime, endDateWithTime)
      .then((sincronizations) => {
        let sincronizationsData = sincronizations.data;

        if (pageFiltersEmployees && pageFiltersEmployees.length > 0) {
          sincronizationsData = sincronizationsData.filter((sync) =>
            pageFiltersEmployees.some(
              (employee) => employee.value === sync.whoSyncId
            )
          );
        }

        if (
          pageFiltersTeams &&
          pageFiltersTeams.value !== "00000000-0000-0000-0000-000000000000"
        ) {
          const selectedTeam = teamsList.find(
            (team) => team.value === pageFiltersTeams.value
          );
          if (selectedTeam) {
            const teamMemberIds = selectedTeam.members.map(
              (member) => member.userId
            );
            sincronizationsData = sincronizationsData.filter((sync) =>
              teamMemberIds.includes(sync.whoSyncId)
            );
          }
        }

        if (sincronizationsData) {
          const processedSorted = sincronizationsData
            .filter((s) => s.status === "Processado")
            .sort(
              (a, b) =>
                new Date(b.dateOfSincronization) -
                new Date(a.dateOfSincronization)
            );

          const errorSorted = sincronizationsData
            .filter((s) => {
              if (s.status === "Erro") {
                try {
                  const errorObject = JSON.parse(s.error);
                  return (
                    errorObject.message !==
                    "Request failed with status code 401"
                  );
                } catch (e) {
                  return true;
                }
              }
              return false;
            })
            .sort(
              (a, b) =>
                new Date(b.dateOfSincronization) -
                new Date(a.dateOfSincronization)
            );

          setSincronizationsProcessed(processedSorted);         
          setSincronizationsWithError(errorSorted);          
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar logs de sincronização:", error);
        toast.error("Erro ao buscar logs de sincronização.");
      });
  };

  const getUniqueWhoSyncIds = () => {
    const allSincronizations = [
      ...sincronizationsProcessed,
      ...sincronizationsWithError,
    ];
    const uniqueIds = new Set();

    allSincronizations.forEach((sync) => {
      if (sync.whoSyncId) {
        uniqueIds.add(sync.whoSyncId);
      }
    });

    return Array.from(uniqueIds);
  };

  const filteredEmployees = React.useMemo(() => {
    const whoSyncIds = getUniqueWhoSyncIds();
    return employeesList.filter((employee) =>
      whoSyncIds.includes(employee.userId)
    );
  }, [employeesList, sincronizationsProcessed, sincronizationsWithError]);

  const handleChangeForms = (forms) => {
    try {
      if (forms) {
        const formsParsed = JSON.parse(forms);
        setForms(formsParsed);
        setIsVisitsModalOpen(true);
      } else {
        toast.error("Esse log de Sincronização não contém visitas.");
      }
    } catch (error) {
      console.error("Erro ao analisar dados das visitas:", error);
      toast.error("Erro ao exibir detalhes das visitas.");
    }
  };

  const handleCloseVisitsModal = () => {
    setForms(null);
    setIsVisitsModalOpen(false);
  };

  const getTableColumns = (type) => {
    const columns = [
      {
        name: "Visitas",
        cell: (row) => (
          <button
            onClick={() => handleChangeForms(row.forms)}
            className="btn bg-transparent"
          >
            <i
              className="ni ni-mobile-button text-info"
              style={{ fontSize: 18 }}
            />
          </button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        name: "Download",
        cell: (row) => (
          <CsvDownloadButton
            className="float-right btn btn-primary"
            data={row.forms ? JSON.parse(row.forms) : []}
            filename={`sincronizacao_${row.id}.csv`}
            style={{ width: "55px", marginRight: "0px" }}
            data-tip
            data-for="dataDownload"
          >
            <i className="fa fa-download" />
          </CsvDownloadButton>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: "70px",
      },
      {
        name: "Usuário",
        selector: "whoSyncId",
        cell: (row) => {
          const employee = filteredEmployees.find(
            (employee) => employee.userId === row.whoSyncId
          );
          return employee ? employee.employeeName : "Não encontrado";
        },
        width: "400px",
        sortable: true,
      },
      {
        name: "Qtd Armadilhas",
        selector: "qttTraps",
        width: "150px",
      },
      {
        name: "Qtd Visitas",
        selector: "qttVisits",
        width: "150px",
      },
      {
        name: "Data Sincronização",
        selector: "dateOfSincronization",
        cell: (s) =>
          timeStampFormatedMongo(
            moment(s.dateOfSincronization).subtract(3, "hours"),
            true
          ),
        width: "150px",
        sortable: true,
      },
    ];

    if (type === "Processed") {
      columns.push({
        name: "Data Processado",
        selector: "dateOfSincronizationProcessed",
        cell: (s) =>
          timeStampFormatedMongo(
            moment(s.dateOfSincronizationProcessed).subtract(3, "hours"),
            true
          ),
        sortable: true,
      });
    } else if (type === "Error") {
      columns.push({
        name: "Erro",
        selector: (row) => {
          try {
            const errorObject = JSON.parse(row.error);
            switch (errorObject.message) {
              case "Request failed with status code 400":
                return "400 (Bad Request): Erro na requisição. Reabra a visita, finalize e tente sincronizar novamente.";
              case "Request failed with status code 401":
                return "401 (Unauthorized): Erro de autenticação. Faça o login novamente para fazer a sincronização.";
              case "Request failed with status code 403":
                return "403 (Forbidden): Erro de autorização. O usuário não ter permissão para sincronizar visitas.";
              case "Request failed with status code 404":
                return "404 (Not Found): O recurso solicitado não existe.";
              case "Request failed with status code 500":
                return "500 (Internal Server Error): Erro do servidor. Contate o suporte.";
              default:
                return `Erro: ${errorObject.message}. Contate o suporte.`;
            }
          } catch (e) {
            return row.error;
          }
        },
      });
    }

    return columns;
  };

  const handleDownloadComponentImage = async () => {
    try {
      if (contentToPrintRef.current === null) return;

      const imageToDownloadString = await toPng(contentToPrintRef.current, {
        quality: 1,
        pixelRatio: 2,
      });

      if (!imageToDownloadString || imageToDownloadString === "")
        throw new Error("Ocorreu um erro ao gerar a imagem do gráfico.");

      const link = document.createElement("a");
      link.download = `${headerText}GeneratedAt${generatedAt}.png`;
      link.href = imageToDownloadString;
      link.click();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  const style = {
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    cardBody: {
      padding: "1rem",
    },
    cardHeaderText: {
      color: "black",
      fontWeight: "bold",
      fontSize: "0.97rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    subTitle: {
      padding: "1rem",
      color: "black",
    },
    fullScreenStyle: {
      maxWidth: "80%",
      width: "100vw",
      height: "100vh",
      top: "0",
    },
  };

  return (
    <div ref={contentToPrintRef}>
      <div>
        <Card className="shadow">
          <CardHeader>
            <CardText className="mb-0 mt-0" style={style.cardHeaderText}>
              <span>
                <span>{headerText}</span>
              </span>
              <span
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "13px", fontWeight: "normal" }}>
                  Gerado em {generatedAt}
                </span>
                <span>
                  <CsvDownloadButton
                    className="btn btn-primary"
                    data={dataToExport}
                    filename={`${headerText.replaceAll(" ", "")}${generatedAt}`}
                    style={{ width: "55px", marginRight: "0px" }}
                    data-tip
                    data-for="dataDownload"
                  >
                    <i className="fa fa-download" />
                  </CsvDownloadButton>
                  <ReactTooltip effect="solid" type="info" id="dataDownload">
                    Baixar dados
                  </ReactTooltip>
                </span>
                <span>
                  <Button
                    onClick={() => handleDownloadComponentImage()}
                    color="primary"
                    data-tip
                    data-for="viewDownload"
                    style={{ width: "55px", marginRight: "0px" }}
                  >
                    <i className={"fa fa-image"} />
                  </Button>
                  <ReactTooltip effect="solid" type="info" id="viewDownload">
                    Baixar visualização
                  </ReactTooltip>
                </span>
              </span>
            </CardText>
          </CardHeader>
          <CardBody style={style.cardBody}>
            <Row>
              <Col>
                <div style={style.subTitle}>
                  <h4>Sincronizações realizadas com sucesso</h4>
                </div>
                <DataTable
                  noHeader
                  defaultSortField="Data Sincronização"
                  defaultSortAsc={false}
                  highlightOnHover
                  pagination
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                  columns={getTableColumns("Processed")}
                  data={sincronizationsProcessed}
                  noDataComponent={"Nenhum registro encontrado."}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={style.subTitle}>
                  <h4>Sincronizações realizadas com erro</h4>
                </div>
                <DataTable
                  noHeader
                  defaultSortField="Data Sincronização"
                  defaultSortAsc={false}
                  highlightOnHover
                  pagination
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                  columns={getTableColumns("Error")}
                  data={sincronizationsWithError}
                  noDataComponent={"Nenhum registro encontrado."}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>

      {/* Modal de Visitas */}
      <Modal
        isOpen={isVisitsModalOpen}
        toggle={handleCloseVisitsModal}
        style={style.fullScreenStyle}
      >
        <ModalBody>
          {forms && <VisitsFromLogsModal forms={forms} setForms={setForms} />}
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            style={{ width: "100px" }}
            onClick={handleCloseVisitsModal}
          >
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SincronizationTable;
